import { ILoadingState } from './loading-state.interface.js';
class IValueLoadingState extends ILoadingState {
  /**
   * Define a callback that will be executed on a successful action
   * @param next
   * @param error
   */
  then(next, error) {
    this.result$.subscribe({
      next,
      error
    });
    return this;
  }
  /**
   * Define a callback that will be executed on a failed action
   * @param func
   */
  catch(func) {
    this.result$.subscribe({
      error: func
    });
    return this;
  }
  /**
   * Define a callback that will be executed when the action has completed (Whether it failed or succeeded)
   * @param func
   */
  finally(func) {
    this.result$.subscribe({
      complete: func,
      error: func
    });
    return this;
  }
}
export { IValueLoadingState };