import { Subject, switchMap, isObservable, from, of, tap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { permanentCache } from '../operators/cache.js';
import { LoadingState } from './loading-state.js';
class DelayedLoadingState extends LoadingState {
  constructor(action, modify) {
    const triggerSubject = new Subject();
    super(triggerSubject.pipe(first(), switchMap(() => {
      const data = action();
      if (isObservable(data)) return data;
      if (data instanceof Promise) return from(data);
      return of(data);
    }), map(data => modify?.(data) ?? data)));
    this.triggerSubject = triggerSubject;
    this.trigger$ = this.result$.pipe(tap({
      subscribe: () => this.trigger()
    }), permanentCache());
  }
  trigger() {
    if (this.triggerSubject.closed) return;
    this.triggerSubject.next();
    this.triggerSubject.complete();
  }
}
export { DelayedLoadingState };