function mapToArr(map, mapFn) {
  if (!mapFn) return Array.from(map.values());
  const result = [];
  for (let [key, val] of map) {
    result.push(mapFn(val, key));
  }
  return result;
}
function setToArr(set, map) {
  if (!map) return Array.from(set);
  const result = [];
  for (let val of set) {
    result.push(map(val));
  }
  return result;
}
function objToArr(obj, mapFn) {
  if (!mapFn) return Object.values(obj);
  const result = [];
  for (let key in obj) {
    const val = obj[key];
    result.push(mapFn(val, key));
  }
  return result;
}
function mapArrNotNull(arr, map) {
  const result = [];
  let index = 0;
  for (let val of arr) {
    const newVal = map(val, index++);
    if (newVal == null) continue;
    result.push(newVal);
  }
  return result;
}
export { mapArrNotNull, mapToArr, objToArr, setToArr };