function latestValueFrom(observable) {
  let hasValue = false;
  let value;
  const sub = observable.subscribe({
    next: x => {
      value = x;
      hasValue = true;
    },
    error: err => {
      throw err;
    }
  });
  sub.unsubscribe();
  if (!hasValue) throw Error("Observable does not have a cached value");
  return value;
}
function latestValueFromOrDefault(observable, defaultValue) {
  if (!observable) return void 0;
  let value = defaultValue;
  const sub = observable.subscribe({
    next: x => value = x,
    error: err => {
      throw err;
    }
  });
  sub.unsubscribe();
  return value;
}
function firstValueFromSubscribable(subject) {
  return new Promise((_resolve, _reject) => {
    let sub;
    const resolve = val => {
      sub?.unsubscribe();
      _resolve(val);
    };
    const reject = val => {
      sub?.unsubscribe();
      _reject(val);
    };
    sub = subject.subscribe({
      next: x => resolve(x),
      error: x => reject(x),
      complete: () => reject(Error("Subscribable never emitted a value"))
    });
  });
}
function firstValueFromSubscribableOrDefault(subject, defaultValue, timeout) {
  return new Promise((_resolve, _reject) => {
    let timer;
    let sub;
    const resolve = val => {
      clearTimeout(timer);
      sub?.unsubscribe();
      _resolve(val);
    };
    const reject = val => {
      clearTimeout(timer);
      sub?.unsubscribe();
      _reject(val);
    };
    if (timeout) {
      setTimeout(() => resolve(defaultValue), timeout);
    }
    sub = subject.subscribe({
      next: x => resolve(x),
      error: x => reject(x),
      complete: () => resolve(defaultValue)
    });
    if (timeout === 0) {
      resolve(defaultValue);
    }
  });
}
export { firstValueFromSubscribable, firstValueFromSubscribableOrDefault, latestValueFrom, latestValueFromOrDefault };