import { of, BehaviorSubject, switchMap } from 'rxjs';
import { Future } from './future.js';
import { FutureConfig } from './future-config.js';
import { parseError } from '@juulsgaard/ts-tools';
function createFuture(value$, loading$, error$) {
  return new Future(value$, loading$ ?? of(false), error$ ?? of(new Error()));
}
function configureFuture(value$, loading$, error$) {
  return new FutureConfig(value$, loading$ ?? of(false), error$ ?? of(new Error()), x => x ?? void 0);
}
function emptyFuture() {
  return new Future(of(void 0), of(false), of(false));
}
function futureFromRequest(req) {
  const val$ = new BehaviorSubject(void 0);
  const err$ = new BehaviorSubject(void 0);
  const load$ = new BehaviorSubject(true);
  req.subscribe({
    next: x => val$.next(x),
    error: err => {
      err$.next(parseError(err));
      err$.complete();
      load$.next(false);
      load$.complete();
      val$.complete();
    },
    complete: () => {
      val$.complete();
      err$.complete();
      load$.next(false);
      load$.complete();
    }
  });
  return new Future(val$, load$, err$);
}
function futureFromLoadingState(loading) {
  return new Future(loading.result$, loading.loading$, loading.failed$.pipe(switchMap(failed => failed ? loading.error$ : of(void 0))));
}
function futureFromObservable(value$) {
  const val$ = new BehaviorSubject(void 0);
  const err$ = new BehaviorSubject(void 0);
  const load$ = new BehaviorSubject(true);
  const sub = value$.subscribe({
    next: x => {
      sub.unsubscribe();
      val$.next(x);
      val$.complete();
      err$.complete();
      load$.next(false);
      load$.complete();
    },
    error: err => {
      err$.next(parseError(err));
      err$.complete();
      load$.next(false);
      load$.complete();
      val$.complete();
    },
    complete: () => {
      val$.complete();
      err$.complete();
      load$.next(false);
      load$.complete();
    }
  });
  return new Future(val$, load$, err$);
}
const constructor = createFuture;
constructor.configure = configureFuture;
constructor.empty = emptyFuture;
constructor.fromRequest = futureFromRequest;
constructor.fromLoadingState = futureFromLoadingState;
constructor.fromObservable = futureFromObservable;
const future = constructor;
export { future };