import { Observable, distinctUntilChanged } from 'rxjs';
class Completed {}
class Failed {
  constructor(error) {
    this.error = error;
  }
}
function pauseBuffer(paused$) {
  return source => {
    return new Observable(subscriber => {
      let completed = false;
      let buffer = [];
      let paused = false;
      const pausedSub = paused$.pipe(distinctUntilChanged()).subscribe(p => {
        paused = p;
        if (!p) emitBuffer();
      });
      function emitBuffer() {
        const localBuffer = buffer;
        buffer = [];
        for (let element of localBuffer) {
          if (element instanceof Failed) {
            subscriber.error(element.error);
            continue;
          }
          if (element instanceof Completed) {
            subscriber.complete();
            break;
          }
          subscriber.next(element);
        }
      }
      const sub = source.subscribe({
        next(value) {
          if (completed) return;
          if (!paused) {
            subscriber.next(value);
            return;
          }
          buffer.push(value);
        },
        error: error => {
          if (completed) return;
          if (!paused) {
            subscriber.error(error);
            return;
          }
          buffer.push(new Failed(error));
        },
        complete: () => {
          completed = true;
          if (!paused) {
            subscriber.complete();
            return;
          }
          buffer.push(Completed);
        }
      });
      return () => {
        sub.unsubscribe();
        pausedSub.unsubscribe();
      };
    });
  };
}
export { pauseBuffer };