import { Observable, startWith } from 'rxjs';
import { future } from '@juulsgaard/rxjs-tools';
import { map } from 'rxjs/operators';
function switchFutureLoad(load) {
  return source => {
    return new Observable(subscriber => {
      let loading;
      const sub = source.subscribe({
        next: payload => {
          const oldLoad = loading;
          loading = load(payload);
          const value$ = loading.result$.pipe(startWith(void 0));
          const futures = future(value$, loading.loading$, loading.error$);
          subscriber.next(futures);
          oldLoad?.cancel();
        },
        error: err => subscriber.error(err),
        complete: () => subscriber.complete()
      });
      return () => {
        sub.unsubscribe();
        loading?.cancel();
      };
    });
  };
}
function mapFuture(value$, loading$, error$) {
  return source => source.pipe(map(payload => future(value$(payload), loading$?.(payload), error$?.(payload))));
}
export { mapFuture, switchFutureLoad };