import { tap } from 'rxjs';
import { logSuccessfulAction, logFailedAction } from '../models/logging.js';
import { ActionCancelledError } from '../models/errors.js';
import { map } from 'rxjs/operators';
import { QueueAction } from '../models/queue-action.js';
import { retryAction } from '../lib/retry.js';
import { AsyncPayloadCommand } from '../models/base-commands.js';
import { Loading } from '@juulsgaard/rxjs-tools';
class ActionCommand extends AsyncPayloadCommand {
  constructor(context, options, reducer) {
    super(context, options.requestId);
    this.options = options;
    this.reducer = reducer;
    this.isSync = false;
  }
  get initialLoad() {
    return this.options.initialLoad;
  }
  alreadyLoaded(payload) {
    if (!this.options.initialLoad) return false;
    if (this.getRequestId) {
      return this.context.getLoadState(this, this.getRequestId(payload)) !== void 0;
    }
    return this.context.getLoadState(this, void 0) !== void 0;
  }
  cancelConcurrent(payload) {
    if (!this.options.cancelConcurrent) return false;
    if (this.getRequestId) {
      return (this.context.getLoadState(this, this.getRequestId(payload)) ?? 0) > 0;
    }
    return (this.context.getLoadState(this, void 0) ?? 0) > 0;
  }
  /**
   * Dispatch the command and return a LoadingState to monitor command progress
   * @param payload - The command payload
   */
  observe(payload) {
    return this.execute(payload, false);
  }
  /**
   * Dispatch the command and return a LoadingState to monitor command progress
   * Will load initial load commands even if they have already been loaded
   * @param payload - The command payload
   */
  forceObserve(payload) {
    return this.execute(payload, true);
  }
  /**
   * Dispatch the command and return a LoadingState to monitor command progress
   * @param payload - The command payload
   * @param ignoreInitial - Ignore initial load constraint
   */
  execute(payload, ignoreInitial) {
    if (!ignoreInitial) {
      if (this.alreadyLoaded(payload)) {
        return Loading.FromError(() => new ActionCancelledError("This action has already been loaded", payload));
      }
      if (this.cancelConcurrent(payload)) {
        return Loading.FromError(() => new ActionCancelledError("Actions was cancelled because another is already running", payload));
      }
    }
    const requestId = this.getRequestId?.(payload);
    this.context.startLoad(this, requestId);
    const action = () => this.options.action(payload);
    const loadState = Loading.Delayed(this.options.retries ? retryAction(action, this.options.retries, this.context.errorIsCritical, this.logRetry.bind(this)) : action, this.options.modify);
    const execute = () => {
      const startedAt = Date.now();
      return loadState.trigger$.pipe(
      // Log errors
      tap({
        error: error => this.onFailure(payload, error, startedAt)
      }),
      // Generate reducer
      map(result => {
        this.onSuccess(payload, result, startedAt);
        return state => this.reducer(state, result, payload);
      }));
    };
    this.context.applyCommand(new QueueAction(this, execute, () => loadState.cancel(), this.options.queue));
    loadState.then(data => {
      this.context.endLoad(this, requestId);
      this.options.preEffect?.(data, payload);
      setTimeout(() => this.options.afterEffect?.(data, payload));
    }).catch(e => this.context.failLoad(this, e, requestId));
    return loadState;
  }
  logRetry(attempt, nextDelay) {
    this.context.logActionRetry(this.name, attempt, nextDelay);
  }
  /**
   * Handle a successful action
   * @param payload
   * @param result
   * @param startedAt
   * @private
   */
  onSuccess(payload, result, startedAt) {
    if (this.options.successMessage) {
      const message = this.options.successMessage instanceof Function ? this.options.successMessage(result, payload) : this.options.successMessage;
      this.context.displaySuccess(message);
    }
    if (!this.context.isProduction) logSuccessfulAction(this.name, void 0, startedAt, payload, result);
  }
  /**
   * Handle a failed action
   * @param payload
   * @param error
   * @param startedAt
   * @private
   */
  onFailure(payload, error, startedAt) {
    if (this.options.showError) {
      this.context.displayError(this.options.errorMessage, error);
    }
    if (!this.context.isProduction) logFailedAction(this.name, startedAt, payload, error);
  }
  /**
   * Emit the command with no status returned
   * @param payload
   */
  emit(payload) {
    this.execute(payload, false);
  }
  /**
   * Emit the command with a Promise status
   * @param payload
   */
  emitAsync(payload) {
    return this.execute(payload, false).resultAsync;
  }
}
export { ActionCommand };