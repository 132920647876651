import { ActionCommandObjectConfig } from './action-command-config.js';
import { DeferredCommandObjectConfig } from './deferred-command-config.js';
import { rootReducerScope } from '../models/reducer-scope.js';
import { PlainCommandObjectConfig } from './plain-command-config.js';
class StoreCommandConfig {
  constructor(context) {
    this.context = context;
  }
  withAction(action) {
    return new ActionCommandObjectConfig(this.context, {
      action,
      showError: true,
      initialLoad: false,
      queue: false,
      cancelConcurrent: false
    }, rootReducerScope, []);
  }
  withDeferredAction(action) {
    return new DeferredCommandObjectConfig(this.context, {
      action,
      showError: true
    }, rootReducerScope, []);
  }
  /**
   * Create a plain Command
   */
  withPayload() {
    return new PlainCommandObjectConfig(this.context, rootReducerScope, []);
  }
}
class StoreClientCommandConfig {
  constructor(context, client) {
    this.context = context;
    this.client = client;
  }
  withAction(action) {
    return new ActionCommandObjectConfig(this.context, {
      action: action(this.client).bind(this.client),
      showError: true,
      initialLoad: false,
      queue: false,
      cancelConcurrent: false
    }, rootReducerScope, []);
  }
  withDeferredAction(action) {
    return new DeferredCommandObjectConfig(this.context, {
      action: action(this.client).bind(this.client),
      showError: true
    }, rootReducerScope, []);
  }
}
export { StoreClientCommandConfig, StoreCommandConfig };