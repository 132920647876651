class Timespan {
  static seconds(s) {
    return Math.floor(s * 1e3);
  }
  static minutes(m, s = 0) {
    return Timespan.seconds(m * 60) + Timespan.seconds(s);
  }
  static hours(h, m = 0, s = 0) {
    return Timespan.minutes(h * 60) + Timespan.minutes(m, s);
  }
  static days(d, h = 0, m = 0, s = 0) {
    return Timespan.hours(d * 24) + Timespan.hours(h, m, s);
  }
  static weeks(w, d = 0, h = 0, m = 0, s = 0) {
    return Timespan.days(w * 7) + Timespan.days(d, h, m, s);
  }
}
export { Timespan };