import { EMPTY, isObservable, tap, of } from 'rxjs';
class QueueAction {
  constructor(type, action, onCancel, queued = false, runInTransaction = false) {
    this.type = type;
    this.action = action;
    this.onCancel = onCancel;
    this.queued = queued;
    this.runInTransaction = runInTransaction;
  }
  /**
   * Execute the action and return an observable with the resulting reducer
   * Observable fails if the actions fails
   * Unsubscribing from the observable will cancel the action
   * Observable can be empty
   */
  run() {
    const result = this.action();
    if (!result) return EMPTY;
    if (isObservable(result)) {
      return result.pipe(tap({
        unsubscribe: () => this.onCancel?.()
      }));
    }
    return of(result);
  }
}
export { QueueAction };