import { ILoadingState } from './loading-state.interface.js';
import { of, EMPTY } from 'rxjs';
class EmptyLoadingState extends ILoadingState {
  constructor() {
    super(...arguments);
    this.loading$ = of(false);
    this.error$ = EMPTY;
    this.failed$ = of(false);
    this.loading = false;
    this.isAsync = false;
  }
  cancel() {}
  /**
   * Promise implementation, immediate executes
   * @param next - Is executed immediately
   * @param error - Is never executed
   */
  then(next, error) {
    next();
    return this;
  }
  /**
   * Promise implementation, never executes
   * @param func - Is never executed
   */
  catch(func) {
    return this;
  }
  /**
   * Promise implementation, immediate executes
   * @param func - Is executed immediately
   */
  finally(func) {
    func();
    return this;
  }
}
export { EmptyLoadingState };