import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {catchError, Observable, switchMap, tap, throwError} from "rxjs";
import {IdentityService} from "@core/services/identity.service";
import {inject, Injectable, Injector} from "@angular/core";
import {isString} from "@juulsgaard/ts-tools";
import {first} from "rxjs/operators";
import {timeoutError} from "@juulsgaard/rxjs-tools";
import {ApiException} from "@lib/exceptions/api-exception";
import {AuthService} from "@lib/services/auth.service";
import {RefreshTokenAuthService} from "@lib/services/refresh-token-auth.service";

type RequestMethod = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';

@Injectable({providedIn: 'root'})
export class RequestClient {

  private httpClient: HttpClient = inject(HttpClient);
  private injector: Injector = inject(Injector);

  constructor() {

  }

  get(url: string) {
    return new RequestClientRequest(this.httpClient, this.injector, 'GET', url);
  }

  delete(url: string) {
    return new RequestClientRequest(this.httpClient, this.injector, 'DELETE', url);
  }

  post(url: string) {
    return new RequestClientRequestWithBody(this.httpClient, this.injector, 'POST', url);
  }

  put(url: string) {
    return new RequestClientRequestWithBody(this.httpClient, this.injector, 'PUT', url);
  }

  patch(url: string) {
    return new RequestClientRequestWithBody(this.httpClient, this.injector, 'PATCH', url);
  }
}

class RequestClientRequest {

  protected url: string;
  protected body?: any;
  protected headers: {[header: string]: string} = {};
  protected queryParams = new HttpParams();
  protected prefix = 'api';
  protected authToken$?: Observable<string>;
  protected errorHandlers: ((error: ApiException) => void)[] = [];

  constructor(
    private httpClient: HttpClient,
    private injector: Injector,
    private method: RequestMethod,
    url: string
  ) {
    this.url = url.replace(/^\w+:\/\//, '');
  }

  onError(handler: (error: ApiException) => void) {
    this.errorHandlers.push(handler);
  }

  withIdToken() {
    const scope = this.injector.get(IdentityService);
    return this.withAuthToken(scope.identityToken$.pipe(
      timeoutError(5000, () => new ApiException('No identity token supplied', 401))
    ));
  }

  withRefreshToken(authService: RefreshTokenAuthService) {
    this.onError(e => {
      if (e.statusCode !== 401) return;
      authService.invalidateToken();
    });
    return this.withAuthToken(authService.currentRefreshToken$);
  }

  withAuthToken(token: string|Observable<string>|AuthService) {

    if (token instanceof AuthService) {
      this.authToken$ = token.authToken$;
      this.onError(e => {
        if (e.statusCode !== 401) return;
        token.invalidateToken();
      });
      return this;
    }

    if (isString(token)) {
      return this.withHeader('Authorization', `Bearer ${token}`);
    }

    this.authToken$ = token;
    return this;
  }

  withQuery(prop: string, value?: string) {
    if (value == null) return this;
    this.queryParams = this.queryParams.set(prop, value);
    return this;
  }

  withHeader(prop: string, value: string) {
    this.headers[prop] = value;
    return this;
  }

  withConnectionId(connectionId: string|undefined) {
    if (!connectionId?.length) return this;
    return this.withHeader('X-Connection-ID', connectionId);
  }

  go<TData = void>(): Observable<TData> {

    if (this.authToken$) {
      return this.authToken$.pipe(
        first(),
        switchMap(token => this.sendRequest<TData>({headers: {'Authorization': `Bearer ${token}`}})),
        catchError(error => {
          const exception = this.getException(error);
          this.errorHandlers.forEach(func => func(exception));
          return throwError(() => exception);
        })
      );
    }

    return this.sendRequest<TData>();
  }

  load<TData extends {loaded: true}>(): Observable<TData> {
    return this.go<TData>().pipe(tap(x => x.loaded = true));
  }

  private sendRequest<TData>(options?: {headers?: {[header: string]: string}}): Observable<TData> {
    const url = `${this.prefix}://${this.url}`;
    return this.httpClient.request<TData>(this.method, url, {body: this.body, headers: {...this.headers, ...options?.headers}, params: this.queryParams});
  }

  private getException(error: ApiException|HttpErrorResponse|unknown): ApiException {
    if (error instanceof ApiException) return error;
    if (error instanceof HttpErrorResponse) {
      return new ApiException(
        error.error?.error ?? 'Something went wrong',
        error.status,
        error.headers.get('X-Correlation-ID') ?? undefined
      );
    }
    return new ApiException("Something went wrong", 500);
  }
}

class RequestClientRequestWithBody extends RequestClientRequest {

  constructor(httpClient: HttpClient, injector: Injector, method: RequestMethod, url: string) {
    super(httpClient, injector, method, url);
  }

  withBody(body: any) {
    this.body = body;
    return this;
  }

  withFormData(body: Record<string, any>) {
    const data = new FormData();
    for (let key in body) {
      data.set(key, body[key]);
    }
    this.body = data;
    return this;
  }
}
