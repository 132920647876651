import { isString } from './type-predicates.js';
function openUrl(url, newTab = false) {
  const anchor = document.createElement("a");
  anchor.href = url;
  if (newTab) anchor.target = "_blank";
  document.body.append(anchor);
  anchor.click();
  anchor.remove();
}
function trimUrlPath(path) {
  return path.trim().replace(/(^\/+|\/+$)/, "").toLowerCase();
}
function applyQueryParam(url, prop, value) {
  if (!url) return url;
  return url.includes("?") ? `${url}&${prop}=${value}` : `${url}?${prop}=${value}`;
}
function downloadUrl(url, filename) {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.style.display = "none";
  if (filename) {
    a.download = filename;
  }
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    a.remove();
  }, 500);
}
function downloadJson(json, filename) {
  const a = document.createElement("a");
  const file = new Blob([isString(json) ? json : JSON.stringify(json)], {
    type: "application/json"
  });
  a.href = URL.createObjectURL(file);
  a.style.display = "none";
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    a.remove();
  }, 500);
}
export { applyQueryParam, downloadJson, downloadUrl, openUrl, trimUrlPath };