import { QueueAction } from '../models/queue-action.js';
import { StoreCommand } from '../models/base-commands.js';
class PlainCommand extends StoreCommand {
  constructor(context, reducer) {
    super(context);
    this.reducer = reducer;
    this.isSync = true;
  }
  get initialLoad() {
    return false;
  }
  emit(payload) {
    this.context.applyCommand(new QueueAction(this, () => state => this.reducer(state, payload)));
  }
}
export { PlainCommand };