function sortDateAsc(getDate) {
  if (!getDate) return (a, b) => (a?.getTime() ?? 0) - (b?.getTime() ?? 0);
  return (a, b) => {
    const numA = getDate(a);
    const numB = getDate(b);
    return (numA?.getTime() ?? 0) - (numB?.getTime() ?? 0);
  };
}
function sortDateDesc(getDate) {
  if (!getDate) return (a, b) => (b?.getTime() ?? 0) - (a?.getTime() ?? 0);
  return (a, b) => {
    const numA = getDate(a);
    const numB = getDate(b);
    return (numB?.getTime() ?? 0) - (numA?.getTime() ?? 0);
  };
}
export { sortDateAsc, sortDateDesc };