import { timeout, of, throwError } from 'rxjs';
function timeoutDefault(duration, defaultVal) {
  return obs => obs.pipe(timeout({
    first: duration,
    with: () => of(defaultVal)
  }));
}
function timeoutError(duration, error) {
  return obs => obs.pipe(timeout({
    first: duration,
    with: () => throwError(error)
  }));
}
export { timeoutDefault, timeoutError };