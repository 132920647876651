import { BehaviorSubject, ReplaySubject, Observable, startWith, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { parseError } from '@juulsgaard/ts-tools';
import { permanentCache } from '../operators/cache.js';
import { CancelledError } from './cancelled.error.js';
import { IValueLoadingState } from './value-loading-state.interface.js';
class LoadingState extends IValueLoadingState {
  constructor(data) {
    super();
    this._loading$ = new BehaviorSubject(true);
    /** The internal result state */
    this._result$ = new ReplaySubject(1);
    /** @inheritDoc */
    this.isAsync = true;
    this.result$ = this._result$.asObservable();
    this.loading$ = this._loading$.asObservable();
    this.error$ = new Observable(subscriber => {
      this.result$.subscribe({
        error: err => {
          subscriber.next(err);
          subscriber.complete();
        },
        complete: () => subscriber.complete()
      });
    }).pipe(permanentCache());
    this.failed$ = this.error$.pipe(map(() => true), startWith(false)).pipe(permanentCache());
    if (data instanceof Promise) {
      data.then(val => this.setValue(val), error => this.setError(error));
      return;
    }
    this.subscription = data.subscribe({
      next: val => this.setValue(val),
      error: error => this.setError(error),
      complete: () => this.setError("Observable completed without value")
    });
  }
  /** @inheritDoc */
  get loading() {
    return this._loading$.value;
  }
  /** A promise returning the data once resolved */
  get resultAsync() {
    if (this._asyncResult) return this._asyncResult;
    this._asyncResult = lastValueFrom(this.result$);
    return this._asyncResult;
  }
  /**
   * Set the value of the state
   * @param val
   * @private
   */
  setValue(val) {
    this._result$.next(val);
    this._result$.complete();
    this._loading$.next(false);
    this._loading$.complete();
    this.subscription?.unsubscribe();
  }
  /**
   * Emit an error
   * @param error
   * @private
   */
  setError(error) {
    this._result$.error(parseError(error));
    this._result$.complete();
    this._loading$.next(false);
    this._loading$.complete();
    this.subscription?.unsubscribe();
  }
  /**
   * Cancel the command
   * This will cancel HTTP requests if used
   */
  cancel() {
    this.subscription?.unsubscribe();
    if (this._result$.closed) return;
    this.setError(new CancelledError());
  }
}
export { LoadingState };