import { Observable, concatMap } from 'rxjs';
function switchLoad(load, onError) {
  return source => {
    return new Observable(subscriber => {
      let loading;
      let errorSub;
      const sub = source.subscribe({
        next: value => {
          errorSub?.unsubscribe();
          loading?.cancel();
          loading = load(value);
          if (onError) {
            errorSub = loading.error$.subscribe(e => onError(e, value));
          }
          subscriber.next(value);
        },
        error: err => subscriber.error(err),
        complete: () => subscriber.complete()
      });
      return () => {
        sub.unsubscribe();
        errorSub?.unsubscribe();
        loading?.cancel();
      };
    });
  };
}
function queuedLoad(load, onError) {
  return source => {
    return source.pipe(
    // Load one at a time
    concatMap(value => new Observable(subscriber => {
      const loading = load(value);
      const errorSub = onError ? loading.error$.subscribe(e => onError(e)) : void 0;
      subscriber.next(value);
      const sub = loading.subscribe({
        error: () => subscriber.complete(),
        complete: () => subscriber.complete()
      });
      return () => {
        sub.unsubscribe();
        errorSub?.unsubscribe();
        loading?.cancel();
      };
    })));
  };
}
export { queuedLoad, switchLoad };