function isObject(obj) {
  return obj != null && typeof obj === "object";
}
function isString(str) {
  return typeof str === "string";
}
function isNumber(num) {
  return typeof num === "number";
}
function isBool(bool) {
  return bool === true || bool === false;
}
function isArray(arr) {
  return Array.isArray(arr);
}
function hasId(obj) {
  return isObject(obj) && "id" in obj;
}
function isFunction(value) {
  return typeof value === "function";
}
export { hasId, isArray, isBool, isFunction, isNumber, isObject, isString };