import { BehaviorSubject, skip, pairwise, concatMap, from, share, Observable } from 'rxjs';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { cache } from '../operators/cache.js';
class ObservableSet {
  get _set() {
    return this._set$.value;
  }
  get value() {
    return this._set;
  }
  get size() {
    return this._set.size;
  }
  get empty() {
    return this.size <= 0;
  }
  get array() {
    return Array.from(this.value);
  }
  constructor(values) {
    this._set$ = new BehaviorSubject(new Set(values));
    this.value$ = this._set$.asObservable();
    this.updates$ = this.value$.pipe(skip(1));
    this.itemUpdates$ = this.value$.pipe(pairwise(), map(([last, next]) => this.processChanges(last, next)), concatMap(x => from(x)), share());
    this.itemRemoved$ = this.itemUpdates$.pipe(filter(x => x.change === "removed"), map(x => x.item), share());
    this.itemAdded$ = this.itemUpdates$.pipe(filter(x => x.change === "added"), map(x => x.item), share());
    this.itemDelta$ = new Observable(subscriber => {
      for (let change of this.processChanges( /* @__PURE__ */new Set(), this.value)) {
        subscriber.next(change);
      }
      return this.itemUpdates$.subscribe(subscriber);
    });
    this.size$ = this.value$.pipe(map(x => x.size), distinctUntilChanged());
    this.empty$ = this.size$.pipe(map(x => x <= 0), distinctUntilChanged());
    this.array$ = this.value$.pipe(map(x => Array.from(x)));
  }
  [Symbol.iterator]() {
    return this._set[Symbol.iterator]();
  }
  subscribe(observer) {
    return this.value$.subscribe(observer);
  }
  getCopy() {
    return new Set(this._set);
  }
  //<editor-fold desc="Actions">
  filter(whitelist) {
    const length = whitelist && "size" in whitelist ? whitelist.size : whitelist?.length;
    if (!length) {
      return this.clear();
    }
    const whitelistSet = whitelist instanceof Set ? whitelist : new Set(whitelist);
    const set = this.getCopy();
    for (let value of this._set) {
      if (whitelistSet.has(value)) continue;
      set.delete(value);
    }
    if (this._set.size !== set.size) {
      this._set$.next(set);
      return true;
    }
    return false;
  }
  clear() {
    if (!this._set.size) return false;
    this._set$.next( /* @__PURE__ */new Set());
    return true;
  }
  add(value) {
    if (this._set.has(value)) return false;
    const set = this.getCopy();
    set.add(value);
    this._set$.next(set);
    return true;
  }
  addRange(values) {
    const set = this.getCopy();
    const size = set.size;
    values.forEach(v => set.add(v));
    if (set.size === size) return false;
    this._set$.next(set);
    return true;
  }
  set(values = []) {
    if (!values.length && !this.size) return false;
    if (values.length === this._set.size) {
      const same = values.every(x => this.has(x));
      if (same) return false;
    }
    this._set$.next(new Set(values));
    return true;
  }
  delete(value) {
    if (!this._set.has(value)) return false;
    const set = this.getCopy();
    set.delete(value);
    this._set$.next(set);
    return true;
  }
  deleteRange(values) {
    const set = this.getCopy();
    const size = set.size;
    values.forEach(v => set.delete(v));
    if (set.size === size) return false;
    this._set$.next(set);
    return true;
  }
  /**
   * Toggle a value in the set
   * @param value - The value to toggle
   * @param state - A forced state (`true` = always add, `false` = always delete)
   * @returns The applied change (`true` = item added, `false` = item removed, `undefined` = nothing changed)
   */
  toggle(value, state) {
    if (this._set.has(value)) {
      if (state === true) return void 0;
      const set2 = this.getCopy();
      set2.delete(value);
      this._set$.next(set2);
      return false;
    }
    if (state === false) return void 0;
    const set = this.getCopy();
    set.add(value);
    this._set$.next(set);
    return true;
  }
  has(value) {
    return this._set.has(value);
  }
  has$(value) {
    return this.value$.pipe(map(x => x.has(value)), distinctUntilChanged(), cache());
  }
  modify(modify) {
    const set = this.getCopy();
    modify(set);
    this._set$.next(set);
  }
  /**
   * Processes changes to individual items
   * @private
   */
  *processChanges(prevSet, nextSet) {
    const old = new Set(prevSet);
    for (let item of nextSet) {
      if (old.has(item)) {
        old.delete(item);
        continue;
      }
      yield {
        item,
        change: "added"
      };
    }
    for (let item of old) {
      yield {
        item,
        change: "removed"
      };
    }
  }
  //</editor-fold>
}
export { ObservableSet };