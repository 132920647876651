function lowerFirst(str) {
  if (!str) return str;
  return str[0].toLowerCase() + str.substring(1);
}
function upperFirst(str) {
  if (!str) return str;
  return str[0].toUpperCase() + str.substring(1);
}
function titleCase(str) {
  if (!str) return str;
  return upperFirst(str.trim().replace(/[-\s]+(\w)/g, (_, c) => ` ${c.toUpperCase()}`).replace(/(\w)([A-Z])/g, (_, a, b) => `${a} ${b}`));
}
function slugify(str) {
  if (!str) return str;
  return str.toLowerCase().trim().replace(/\W+/g, "-");
}
function pascalCase(str) {
  if (!str) return str;
  return upperFirst(str.trim().replace(/[-\s]+(\w)/g, (_, c) => c.toUpperCase()));
}
function camelCase(str) {
  if (!str) return str;
  return lowerFirst(str.trim().replace(/[-\s]+(\w)/g, (_, c) => c.toUpperCase()));
}
function dashCase(str) {
  if (!str) return str;
  return lowerFirst(str.trim()).replace(/[A-Z]|[-\s]+\w/g, x => `-${x.toLowerCase()}`);
}
function stripTags(str) {
  if (!str) return str;
  let tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
}
function getNameInitials(name, lastName) {
  if (!name.length) return "XX";
  if (lastName && lastName.length > 0) {
    return name[0] + lastName[0];
  }
  const segments = name.trim().split(/\s+/);
  if (segments.length > 1) {
    return segments[0][0].toUpperCase() + segments[segments.length - 1][0].toUpperCase();
  }
  if (segments[0].length < 2) {
    return segments[0][0].toUpperCase();
  }
  return segments[0][0].toUpperCase() + segments[0][1].toLowerCase();
}
export { camelCase, dashCase, getNameInitials, lowerFirst, pascalCase, slugify, stripTags, titleCase, upperFirst };