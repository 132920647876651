function sortBoolAsc(getBool) {
  if (!getBool) return (a, b) => a ? b ? 0 : 1 : b ? -1 : 0;
  return (a, b) => {
    const boolA = getBool(a);
    const boolB = getBool(b);
    return boolA ? boolB ? 0 : 1 : boolB ? -1 : 0;
  };
}
function sortBoolDesc(getBool) {
  if (!getBool) return (a, b) => b ? a ? 0 : 1 : a ? -1 : 0;
  return (a, b) => {
    const boolA = getBool(a);
    const boolB = getBool(b);
    return boolB ? boolA ? 0 : 1 : boolA ? -1 : 0;
  };
}
export { sortBoolAsc, sortBoolDesc };