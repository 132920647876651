import { combineLatest, distinctUntilChanged, Subject, tap, switchMap, of, EMPTY } from 'rxjs';
import { logSuccessfulAction, logActionInformation, logFailedAction } from '../models/logging.js';
import { ActionCancelledError, CacheCommandError } from '../models/errors.js';
import { map } from 'rxjs/operators';
import { QueueAction } from '../models/queue-action.js';
import { PlainCommand } from './plain-command.js';
import { AsyncCommand, AsyncPayloadCommand } from '../models/base-commands.js';
import { parseIdMap } from '../lib/id-map.js';
import { Loading } from '@juulsgaard/rxjs-tools';
class CacheCommand extends AsyncCommand {
  constructor(context, options, reducer, fallbackCommand, concurrentFallback = false) {
    super(context);
    this.options = options;
    this.reducer = reducer;
    this.fallbackCommand = fallbackCommand;
    this.concurrentFallback = concurrentFallback;
    this.isSync = false;
    this.getRequestId = this.options.requestId && parseIdMap(this.options.requestId);
    this.cacheLoading$ = this.loading$;
    this.cacheLoaded$ = this.loaded$;
    this.cacheFailed$ = this.failed$;
    if (fallbackCommand instanceof AsyncCommand) {
      this.loading$ = combineLatest([this.cacheLoading$, fallbackCommand.loading$]).pipe(map(([x, y]) => x || y), distinctUntilChanged());
      this.loaded$ = combineLatest([this.cacheLoaded$, fallbackCommand.loaded$]).pipe(map(([x, y]) => x || y), distinctUntilChanged());
      this.failed$ = combineLatest([this.cacheFailed$, fallbackCommand.failed$]).pipe(map(([x, y]) => x || y), distinctUntilChanged());
    }
  }
  get initialLoad() {
    return this.options.initialLoad;
  }
  //<editor-fold desc="Cache Request Loading State">
  cacheLoadingById$(payload) {
    if (!this.getRequestId) return this.loading$;
    return this.context.getLoadState$(this, this.getRequestId(payload)).pipe(map(x => !!x && x > 0), distinctUntilChanged());
  }
  cacheLoadedById$(payload) {
    if (!this.getRequestId) return this.loaded$;
    return this.context.getLoadState$(this, this.getRequestId(payload)).pipe(map(x => x !== void 0), distinctUntilChanged());
  }
  cacheFailedById$(payload) {
    if (!this.getRequestId) return this.failed$;
    return this.context.getFailureState$(this, this.getRequestId(payload)).pipe(distinctUntilChanged());
  }
  cacheErrorById$(payload) {
    if (!this.getRequestId) return this.error$;
    return this.context.getErrorState$(this, this.getRequestId(payload)).pipe(distinctUntilChanged());
  }
  loadingById$(cachePayload, commandPayload) {
    if (!this.getRequestId) return this.loading$;
    if (!this.fallbackCommand || !("loadingById$" in this.fallbackCommand)) return this.cacheLoadingById$(cachePayload);
    const cmdPayload = commandPayload ?? cachePayload;
    return combineLatest([this.cacheLoadingById$(cachePayload), this.fallbackCommand.loadingById$(cmdPayload)]).pipe(map(([x, y]) => x || y), distinctUntilChanged());
  }
  loadedById$(cachePayload, commandPayload) {
    if (!this.getRequestId) return this.loading$;
    if (!this.fallbackCommand || !("loadedById$" in this.fallbackCommand)) return this.cacheLoadedById$(cachePayload);
    const cmdPayload = commandPayload ?? cachePayload;
    return combineLatest([this.cacheLoadedById$(cachePayload), this.fallbackCommand.loadedById$(cmdPayload)]).pipe(map(([x, y]) => x || y), distinctUntilChanged());
  }
  failedById$(cachePayload, commandPayload) {
    if (!this.getRequestId) return this.failed$;
    if (!this.fallbackCommand || !("failedById$" in this.fallbackCommand)) return this.cacheFailedById$(cachePayload);
    const cmdPayload = commandPayload ?? cachePayload;
    return combineLatest([this.cacheFailedById$(cachePayload), this.fallbackCommand.failedById$(cmdPayload)]).pipe(map(([x, y]) => x || y), distinctUntilChanged());
  }
  errorById$(cachePayload, commandPayload) {
    if (!this.getRequestId) return this.error$;
    if (!this.fallbackCommand || !("errorById$" in this.fallbackCommand)) return this.cacheErrorById$(cachePayload);
    const cmdPayload = commandPayload ?? cachePayload;
    return combineLatest([this.cacheErrorById$(cachePayload), this.fallbackCommand.errorById$(cmdPayload)]).pipe(map(([x, y]) => x ?? y), distinctUntilChanged());
  }
  //</editor-fold>
  valueIsValid(data) {
    return data != void 0 && !this.options.failCondition?.(data);
  }
  alreadyLoaded(payload) {
    if (!this.options.initialLoad) return false;
    if (this.getRequestId) {
      return this.context.getLoadState(this, this.getRequestId(payload)) !== void 0;
    }
    return this.context.getLoadState(this, void 0) !== void 0;
  }
  cancelConcurrent(payload) {
    if (!this.options.cancelConcurrent) return false;
    if (this.getRequestId) {
      return (this.context.getLoadState(this, this.getRequestId(payload)) ?? 0) > 0;
    }
    return (this.context.getLoadState(this, void 0) ?? 0) > 0;
  }
  observe(cachePayload, commandPayload) {
    const cmdPayload = commandPayload ?? cachePayload;
    if (this.alreadyLoaded(cachePayload)) {
      return Loading.FromError(() => new ActionCancelledError("This cache action has already been loaded", cachePayload));
    }
    if (this.cancelConcurrent(cachePayload)) {
      return Loading.FromError(() => new ActionCancelledError("Actions was cancelled because another is already running", cachePayload));
    }
    if (this.fallbackCommand && "alreadyLoaded" in this.fallbackCommand) {
      if (this.fallbackCommand.alreadyLoaded(cmdPayload)) {
        return Loading.FromError(() => new ActionCancelledError("This cache fallback action has already been loaded", cachePayload));
      }
    }
    const requestId = this.getRequestId?.(cachePayload);
    this.context.startLoad(this, requestId);
    const sharedState = new Subject();
    const sharedLoadingState = Loading.Async(sharedState);
    const online = typeof navigator == "undefined" ? true : navigator.onLine;
    const maxAge = online ? this.options.onlineMaxAge : this.options.offlineMaxAge;
    const absoluteAge = online ? !!this.options.onlineAbsoluteAge : !!this.options.offlineAbsoluteAge;
    const emitFallback = () => {
      if (this.fallbackCommand instanceof PlainCommand) {
        this.fallbackCommand.emit(cmdPayload);
        sharedState.next();
        return;
      }
      this.fallbackCommand.observe(cmdPayload).then(data => sharedState.next(data)).catch(err => sharedState.error(err));
    };
    const resetFallback = () => {
      if (!this.fallbackCommand) return;
      if (this.fallbackCommand instanceof AsyncPayloadCommand) {
        this.fallbackCommand.resetFailureStateById(cmdPayload);
        return;
      }
      if (this.fallbackCommand instanceof AsyncCommand) {
        this.fallbackCommand.resetFailState();
      }
    };
    if (online && !this.options.cacheWhenOnline) {
      if (this.fallbackCommand) {
        emitFallback();
        this.context.endLoad(this, requestId);
      } else {
        const error = new CacheCommandError("Cache disabled online, but missing fallback", cachePayload);
        sharedState.error(error);
        this.context.failLoad(this, error, requestId);
      }
      return sharedLoadingState;
    }
    const cacheLoad = Loading.Delayed(() => this.options.action({
      options: {
        maxAge,
        absoluteAge
      },
      payload: cachePayload
    }));
    const execute = () => {
      const startedAt = Date.now();
      return cacheLoad.trigger$.pipe(
      // Log errors
      tap({
        error: error => this.onFailure(cachePayload, error, startedAt)
      }),
      // Terminate failed cache reads (undefined)
      switchMap(x => {
        if (this.valueIsValid(x)) return of(x);
        this.onNoCache(cachePayload, startedAt);
        return EMPTY;
      }),
      // Generate reducer
      map(result => {
        this.onSuccess(cachePayload, result, startedAt);
        return state => this.reducer(state, result, cachePayload);
      }));
    };
    this.context.applyCommand(new QueueAction(this, execute, () => cacheLoad.cancel(), true));
    const validateCacheResult = result => {
      if (this.fallbackCommand) {
        if (this.concurrentFallback) {
          if (!online && !this.options.fallbackWhenOffline) {
            if (!this.valueIsValid(result)) {
              throw new CacheCommandError(`Concurrent fallback isn't offline, and no value found in cache`, cachePayload);
            }
            resetFallback();
            return true;
          }
          return false;
        }
        if (this.valueIsValid(result)) {
          resetFallback();
          return true;
        }
        if (!online && !this.options.fallbackWhenOffline) {
          throw new CacheCommandError("No value found in cache, and no offline fallback", cachePayload);
        }
        return false;
      }
      if (!this.valueIsValid(result)) {
        throw new CacheCommandError("No value found in cache, and no fallback", cachePayload);
      }
      return true;
    };
    cacheLoad.then(result => {
      try {
        const valid = validateCacheResult(result);
        this.context.endLoad(this, requestId);
        if (valid) sharedState.next(result);else emitFallback();
      } catch (e) {
        const error = e instanceof Error ? e : Error();
        this.context.failLoad(this, error, requestId);
        sharedState.error(error);
      }
    }).catch(err => {
      this.context.failLoad(this, err, requestId);
      sharedState.error(err);
    });
    return sharedLoadingState;
  }
  /**
   * Handle a successful action
   * @param payload
   * @param result
   * @param startedAt
   * @private
   */
  onSuccess(payload, result, startedAt) {
    if (this.options.successMessage) {
      const message = this.options.successMessage instanceof Function ? this.options.successMessage(result, payload) : this.options.successMessage;
      this.context.displaySuccess(message);
    }
    if (!this.context.isProduction) logSuccessfulAction(this.name, void 0, startedAt, payload, result);
  }
  /**
   * Handle a successful cache load with no content
   * @param payload
   * @param startedAt
   * @private
   */
  onNoCache(payload, startedAt) {
    if (!this.context.isProduction) {
      logActionInformation(this.name, "Cache is empty", startedAt, payload);
    }
  }
  /**
   * Handle a failed action
   * @param payload
   * @param error
   * @param startedAt
   * @private
   */
  onFailure(payload, error, startedAt) {
    if (this.options.errorMessage) {
      this.context.displayError(this.options.errorMessage, error);
    }
    if (!this.context.isProduction) logFailedAction(this.name, startedAt, payload, error);
  }
  emit(cachePayload, commandPayload) {
    this.observe(cachePayload, commandPayload);
  }
  emitAsync(cachePayload, commandPayload) {
    return this.observe(cachePayload, commandPayload).resultAsync;
  }
}
export { CacheCommand };