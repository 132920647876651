import { concatMap, from } from 'rxjs';
import { filter, map } from 'rxjs/operators';
function filterAsync(filterFunc) {
  return source => {
    return source.pipe(concatMap(val => from(filterFunc(val)).pipe(filter(x => x), map(() => val))));
  };
}
function mapAsync(mapFunc) {
  return source => {
    return source.pipe(concatMap(val => from(mapFunc(val))));
  };
}
export { filterAsync, mapAsync };