import { BehaviorSubject, skip, pairwise, concatMap, from, share, Observable, startWith } from 'rxjs';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { cache, persistentCache } from '../operators/cache.js';
import { arrToLookup } from '@juulsgaard/ts-tools';
class ObservableStack {
  constructor() {
    this._items$ = new BehaviorSubject([]);
    /** An observable containing the items of the stack from bottom to top */
    this.items$ = this._items$.asObservable();
    this.updates$ = this.items$.pipe(skip(1));
    this.itemUpdates$ = this.items$.pipe(pairwise(), map(([last, next]) => this.processChanges(last, next)), concatMap(x => from(x)), share());
    this.itemRemoved$ = this.itemUpdates$.pipe(filter(x => x.change === "removed"), map(({
      item,
      index
    }) => ({
      item,
      index
    })), share());
    this.itemAdded$ = this.itemUpdates$.pipe(filter(x => x.change === "added"), map(({
      item,
      index
    }) => ({
      item,
      index
    })), share());
    this.itemDelta$ = new Observable(subscriber => {
      for (let change of this.processChanges([], this.items)) {
        subscriber.next(change);
      }
      return this.itemUpdates$.subscribe(subscriber);
    });
    this.top$ = this.items$.pipe(map(x => x.at(-1)), distinctUntilChanged(), cache());
    this.topDelta$ = this.items$.pipe(startWith([]), pairwise(), map(([prevList, nextList]) => {
      const item = nextList.at(-1);
      return {
        item,
        added: !item ? false : !prevList.includes(item)
      };
    }), distinctUntilChanged((prev, next) => prev.item === next.item), share());
    this.bottom$ = this.items$.pipe(map(x => x.at(0)), distinctUntilChanged(), persistentCache());
    this.bottomDelta$ = this.items$.pipe(startWith([]), pairwise(), map(([prevList, nextList]) => {
      const item = nextList.at(0);
      return {
        item,
        added: !item ? false : !prevList.includes(item)
      };
    }), distinctUntilChanged((prev, next) => prev.item === next.item), share());
    this.length$ = this.items$.pipe(map(x => x.length), distinctUntilChanged(), persistentCache());
    this.empty$ = this.length$.pipe(map(x => x <= 0), distinctUntilChanged(), persistentCache());
  }
  /** A list of items in the stack from bottom to top */
  get items() {
    return this._items$.value;
  }
  set items(items) {
    this._items$.next(items);
  }
  get length() {
    return this.items.length;
  }
  get empty() {
    return this.items.length <= 0;
  }
  //<editor-fold desc="Top">
  /** Element at the top of the stack */
  get top() {
    return this.items.at(-1);
  }
  //</editor-fold>
  //<editor-fold desc="Bottom">
  /** Element at the bottom of the stack */
  get bottom() {
    return this.items.at(0);
  }
  /**
   * Processes changes to individual items
   * @param prevList
   * @param nextList
   * @private
   */
  *processChanges(prevList, nextList) {
    const oldLookup = arrToLookup(prevList, x => x, (_, i) => i);
    const changes = [];
    for (let i = 0; i < nextList.length; i++) {
      const data = nextList[i];
      if (data == null) continue;
      const oldItems = oldLookup.get(data);
      if (!oldItems) {
        changes.push({
          item: data,
          index: i,
          change: "added"
        });
        continue;
      }
      const oldIndex = oldItems.shift();
      if (oldIndex !== void 0) continue;
      changes.push({
        item: data,
        index: i,
        change: "added"
      });
    }
    for (let [oldData, indices] of oldLookup) {
      if (oldData == null) continue;
      for (let index of indices) {
        yield {
          item: oldData,
          index,
          change: "removed"
        };
      }
    }
    for (let change of changes) {
      yield change;
    }
  }
  //</editor-fold>
  //<editor-fold desc="Actions">
  /**
   * Removes an item from the scheduler
   * @param item
   */
  removeItem(item) {
    const index = this.items.indexOf(item);
    if (index < 0) return false;
    const arr = [...this.items];
    arr.splice(index, 1);
    this.items = arr;
    return true;
  }
  removeFromBottom(count) {
    if (count !== void 0) {
      if (count < 1) return [];
      if (!this.items.length) return [];
      const items = this.items.slice(0, count);
      this.items = this.items.slice(count);
      return items;
    }
    if (!this.items.length) return void 0;
    const item = this.items.at(0);
    this.items = this.items.slice(1);
    return item;
  }
  pop(count) {
    if (count !== void 0) {
      if (count < 1) return [];
      if (!this.items.length) return [];
      const start = count * -1;
      const items = this.items.slice(start);
      this.items = this.items.slice(0, start);
      return items;
    }
    if (!this.items.length) return void 0;
    const item = this.items.at(-1);
    this.items = this.items.slice(0, -1);
    return item;
  }
  push(...items) {
    this.items = [...this.items, ...items];
  }
  addToBottom(...items) {
    this.items = [...items, ...this.items];
  }
  /**
   * Checks if the item exists in the queue
   * @param item
   */
  contains(item) {
    return this.items.includes(item);
  }
  /**
   * Remove all elements from the set
   */
  clear() {
    this.items = [];
  }
  //</editor-fold>
  /**
   * Dispose of the Scheduler.
   * This closes all subjects.
   */
  dispose() {
    this._items$.complete();
  }
}
export { ObservableStack };