import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
function notNull() {
  return source => source.pipe(filter(value => value != void 0));
}
function notSync() {
  return source => new Observable(subscriber => {
    let init = true;
    source.pipe(filter(() => !init)).subscribe(subscriber);
    init = false;
  });
}
export { notNull, notSync };