import { of, combineLatest, Observable, Subscription, pairwise, switchMap, take } from 'rxjs';
import { filter, distinctUntilChanged, map, first } from 'rxjs/operators';
import { FutureLoading, FutureError, FutureEmpty, FutureValue } from './future-types.js';
import { cache } from '../operators/cache.js';
class Future {
  constructor(value$, loading$, error$, onLoad, onDeleted) {
    this.rawValue$ = value$.pipe(
    // persistentCache(),
    cache());
    this.value$ = this.rawValue$.pipe(filter(x => x !== void 0), distinctUntilChanged());
    const emptyError = new Error();
    const errors$ = error$?.pipe(map(error => {
      if (!error) return void 0;
      if (error === true) return emptyError;
      if (error instanceof Error) return error;
      return new Error(error);
    })) ?? of(void 0);
    let combined = combineLatest([this.rawValue$, loading$, errors$]).pipe(distinctUntilChanged(([oldVal, oldLoading, oldError], [val, loading, error]) => oldVal === val && oldLoading === loading && oldError === error), cache());
    this.canLoad$ = combined.pipe(map(([value, loading]) => !value && !loading));
    this.state$ = new Observable(subscriber => {
      const sub = new Subscription();
      sub.add(combined.pipe(map(([value, loading, error]) => {
        if (loading) return new FutureLoading(value ?? void 0);
        if (error) return new FutureError(error, value ?? void 0);
        if (value === void 0) return new FutureEmpty();
        return new FutureValue(value);
      }), distinctUntilChanged((a, b) => !Future.stateChanged(a, b))).subscribe(subscriber));
      if (onLoad) {
        sub.add(combined.pipe(first(([_, loading]) => !loading), filter(([val]) => !val)).subscribe(() => onLoad()));
      }
      if (onDeleted) {
        sub.add(combined.pipe(distinctUntilChanged(([oldVal], [newVal]) => oldVal !== newVal), pairwise(),
        // If value has been removed
        filter(([[oldVal], [newVal]]) => oldVal !== void 0 && newVal === void 0),
        // Wait for loading to end
        switchMap(() => combined.pipe(filter(([_, loading]) => !loading), take(1))),
        // If value still isn't present
        filter(([val]) => !val)).subscribe(() => onDeleted?.()));
      }
      return sub;
    }).pipe(cache());
    this.data$ = this.state$.pipe(map(x => x instanceof FutureValue ? x.value : void 0));
    this.loading$ = this.state$.pipe(map(x => x instanceof FutureLoading));
    this.empty$ = this.state$.pipe(map(x => x instanceof FutureEmpty));
    this.waiting$ = this.state$.pipe(map(x => x instanceof FutureLoading || x instanceof FutureEmpty));
    this.failed$ = this.state$.pipe(map(x => x instanceof FutureError));
    this.error$ = this.state$.pipe(map(x => x instanceof FutureError ? x.error ?? new Error() : void 0));
  }
  /**
   * Change detection for future state
   * @param oldState
   * @param newState
   * @private
   */
  static stateChanged(oldState, newState) {
    if (newState instanceof FutureEmpty) {
      return oldState !== FutureEmpty;
    }
    if (newState instanceof FutureValue) {
      if (oldState instanceof FutureValue) {
        return oldState.value !== newState.value;
      }
      return true;
    }
    if (newState instanceof FutureLoading) {
      if (oldState instanceof FutureLoading) {
        return oldState.value !== newState.value;
      }
      return true;
    }
    if (newState instanceof FutureError) {
      if (oldState instanceof FutureError) {
        return oldState.error !== newState.error || oldState.value !== newState.value;
      }
      return true;
    }
    return oldState !== newState;
  }
  subscribe(observer) {
    return this.state$.subscribe(observer);
  }
}
export { Future };