import { tap } from 'rxjs';
import { isFunction } from '@juulsgaard/ts-tools';
function subscribed(state) {
  const setState = isFunction(state) ? state : state.next.bind(state);
  return tap({
    subscribe: () => setState(true),
    unsubscribe: () => setState(false)
  });
}
function active(state) {
  const setState = isFunction(state) ? state : state.next.bind(state);
  return tap({
    subscribe: () => setState(true),
    finalize: () => setState(false)
  });
}
function loadingFirst(state) {
  const baseSetState = isFunction(state) ? state : state.next.bind(state);
  let lastState = false;
  const setState = state2 => {
    if (state2 === lastState) return;
    lastState = state2;
    baseSetState(state2);
  };
  return tap({
    subscribe: () => setState(true),
    next: () => setState(false),
    finalize: () => setState(false)
  });
}
export { active, loadingFirst, subscribed };