import { Observable, asyncScheduler } from 'rxjs';
class Completed {}
const completed = new Completed();
class Empty {}
const empty = new Empty();
class Failed {
  constructor(error) {
    this.error = error;
  }
}
class Value {
  constructor(value) {
    this.value = value;
  }
}
function spaceEvenly(delay, scheduler = asyncScheduler) {
  return source => {
    return new Observable(subscriber => {
      const queue = [];
      const emitter = new TimedEmitter(subscriber, delay, () => queue.length > 0, () => queue.shift() ?? empty, scheduler);
      const sub = source.subscribe({
        next(value) {
          queue.push(new Value(value));
          emitter.emit();
        },
        error: error => {
          queue.push(new Failed(error));
          emitter.emit();
        },
        complete: () => {
          queue.push(completed);
          emitter.emit();
        }
      });
      return () => {
        sub.unsubscribe();
        emitter?.unsubscribe();
      };
    });
  };
}
function spaceEvenlyRepeat(delay, maxHistory = 100, minHistory = 1, initialHistory = [], scheduler = asyncScheduler) {
  maxHistory = Math.max(0, maxHistory);
  minHistory = Math.max(1, Math.min(maxHistory, minHistory));
  if (initialHistory.length > maxHistory) {
    initialHistory = initialHistory.slice(-maxHistory);
  }
  return source => {
    return new Observable(subscriber => {
      const queue = [];
      let history = [];
      let futureHistory = initialHistory;
      function getValue() {
        if (queue.length > 0) return queue.shift();
        if (history.length < 1) {
          if (futureHistory.length < minHistory) return empty;
          history = futureHistory;
          futureHistory = [];
        }
        return new Value(history.shift());
      }
      const emitter = new TimedEmitter(subscriber, delay, () => {
        if (queue.length > 0) return true;
        if (history.length > 0) return true;
        return futureHistory.length >= minHistory;
      }, () => {
        const value = getValue();
        if (maxHistory > 0 && value instanceof Value) {
          futureHistory.push(value.value);
          if (futureHistory.length > maxHistory) futureHistory.shift();
        }
        return value;
      }, scheduler);
      const sub = source.subscribe({
        next(value) {
          queue.push(new Value(value));
          emitter.emit();
        },
        error: error => {
          queue.push(new Failed(error));
          emitter.emit();
        },
        complete: () => {
          queue.push(completed);
          emitter.emit();
        }
      });
      return () => {
        sub.unsubscribe();
        emitter?.unsubscribe();
      };
    });
  };
}
class TimedEmitter {
  constructor(subscriber, delay, hasValue, getValue, scheduler = asyncScheduler) {
    this.subscriber = subscriber;
    this.delay = delay;
    this.hasValue = hasValue;
    this.getValue = getValue;
    this.scheduler = scheduler;
  }
  emitInternal() {
    this.timer?.unsubscribe();
    const value = this.getValue();
    if (value instanceof Empty) return;
    if (value instanceof Failed) {
      this.subscriber.error(value.error);
      this.emit();
      return;
    }
    if (value instanceof Completed) {
      this.subscriber.complete();
      return;
    }
    this.lastEmit = this.scheduler.now();
    this.subscriber.next(value.value);
    this.timer = void 0;
    this.emit();
  }
  emit() {
    if (this.timer) return;
    if (!this.hasValue()) return;
    if (!this.lastEmit) {
      this.emitInternal();
      return;
    }
    const now = this.scheduler.now();
    const timeSinceEmit = now - this.lastEmit;
    if (timeSinceEmit >= this.delay) {
      this.emitInternal();
      return;
    }
    this.timer = this.scheduler.schedule(() => this.emitInternal(), this.delay - timeSinceEmit);
  }
  unsubscribe() {
    this.timer?.unsubscribe();
  }
}
export { spaceEvenly, spaceEvenlyRepeat };