function sortNumAsc(getNum) {
  if (!getNum) return (a, b) => (a ?? 0) - (b ?? 0);
  return (a, b) => {
    const numA = getNum(a);
    const numB = getNum(b);
    return (numA ?? 0) - (numB ?? 0);
  };
}
function sortNumDesc(getNum) {
  if (!getNum) return (a, b) => (b ?? 0) - (a ?? 0);
  return (a, b) => {
    const numA = getNum(a);
    const numB = getNum(b);
    return (numB ?? 0) - (numA ?? 0);
  };
}
export { sortNumAsc, sortNumDesc };