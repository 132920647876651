import { isString, isArray } from '../helpers/type-predicates.js';
function processElementClasses(classes) {
  if (classes == null) return [];
  if (isString(classes)) return [classes];
  if (isArray(classes)) return classes;
  const out = [];
  for (let c in classes) {
    if (!classes[c]) continue;
    out.push(c);
  }
  return out;
}
class ElementClassManager {
  constructor(element, initialClasses) {
    this.element = element;
    this._classes = /* @__PURE__ */new Set();
    this._classes = new Set(processElementClasses(initialClasses));
    this.element.classList.add(...this.classes);
  }
  get classes() {
    return this._classes;
  }
  /**
   * Update the applied classes to the given state.
   * New classes will be applied, and classes not in the new state will be removed.
   * @param classes - The new classes state
   */
  set(classes) {
    const current = new Set(this.classes);
    const toSet = processElementClasses(classes);
    for (let c of toSet) {
      const exists = current.delete(c);
      if (exists) continue;
      this._classes.add(c);
      this.element.classList.add(c);
    }
    for (let c of current) {
      this._classes.delete(c);
      this.element.classList.remove(c);
    }
  }
  /**
   * Add classes to the HTML Element.
   * @param classes - The classes to add
   * @returns addedClasses - A list of classes that were added to the state
   */
  add(classes) {
    const toAdd = processElementClasses(classes);
    const added = [];
    for (let c of toAdd) {
      const wasAdded = this._classes.add(c);
      if (!wasAdded) continue;
      this.element.classList.add(c);
      added.push(c);
    }
    return added;
  }
  /**
   * Remove classes from the HTML Element.
   * @param classes - The classes to remove
   * @returns removedClasses - A list of classes that were removed from the state
   */
  remove(classes) {
    const toRemove = processElementClasses(classes);
    const removed = [];
    for (let c of toRemove) {
      const wasDeleted = this._classes.delete(c);
      if (!wasDeleted) continue;
      removed.push(c);
      this.element.classList.remove(c);
    }
    return removed;
  }
}
export { ElementClassManager, processElementClasses };