import {Observable, Subscription} from "rxjs";
import {parseJwt} from "@juulsgaard/ts-tools";
import {map} from "rxjs/operators";
import {permanentCache} from "@juulsgaard/rxjs-tools";
import {TokenResponse} from "@lib/models/generic.models";

interface Token {
  token: string;
  expiry: number;
}

interface TokenData {
  exp: number;
}

export abstract class AuthService {
  abstract readonly authToken$: Observable<string>;
  abstract invalidateToken(): void;
}

export abstract class BaseAuthService extends AuthService {

  private token?: Token;
  private tokenRequest$?: Observable<string>;
  private request?: Subscription;

  get authToken$(): Observable<string> {
    return this.getToken$()
  }

  setToken(token: string) {
    const data = parseJwt<TokenData>(token);
    this.token = {expiry: data.exp - 120, token};
    this.tokenRequest$ = undefined;
    this.request?.unsubscribe();
  }

  private getToken$(): Observable<string> {
    return new Observable<string>(subscriber => {
      if (this.tokenRequest$) return this.tokenRequest$.subscribe(subscriber);

      if (!this.token) {
        this.tokenRequest$ = this.createRequest();
        return this.tokenRequest$.subscribe(subscriber);
      }

      if ((new Date().getTime() / 1000) > this.token.expiry) {
        this.token = undefined;
        this.tokenRequest$ = this.createRequest();
        return this.tokenRequest$.subscribe(subscriber);
      }

      subscriber.next(this.token.token);
      subscriber.complete();
      return;
    });
  }

  private createRequest(): Observable<string> {
    this.request?.unsubscribe();
    const token$ = this.fetchToken().pipe(map(x => x.token), permanentCache());
    this.request = token$.subscribe(token => this.setToken(token));
    return token$;
  }

  protected abstract fetchToken(): Observable<TokenResponse>;

  invalidateToken() {
    console.warn("Invalidating Auth Token");
    this.token = undefined;
    this.request?.unsubscribe();
    this.tokenRequest$ = undefined;
  }

}
