import {Injectable} from '@angular/core';
import {StorageService} from "@lib/services/storage.service";

@Injectable({providedIn: 'root'})
export class LocalStorage extends StorageService {

  protected readonly defaultError = `local storage is not available`;

  protected _isAvailable() {

    try {
      if (window.localStorage == undefined) {
        this.setError('Browser does not support local storage');
        console.warn(this.error);
        return false;
      }

      localStorage.setItem('__test__', 'test');
      localStorage.removeItem('__test__');
    } catch (e: any) {
      console.warn(`Can't access localStorage:`, e.message);
      this.setError('Data storage has been disabled for this site');
      return false;
    }

    return true;
  }

  protected _write(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  protected _delete(key: string) {
    return localStorage.removeItem(key);
  }

  protected _read(key: string) {
    return localStorage.getItem(key);
  }
}
