import { IValueLoadingState } from './value-loading-state.interface.js';
import { of, EMPTY } from 'rxjs';
class StaticLoadingState extends IValueLoadingState {
  constructor(value) {
    super();
    this.value = value;
    this.loading$ = of(false);
    this.error$ = EMPTY;
    this.failed$ = of(false);
    this.loading = false;
    this.isAsync = false;
    this.result$ = of(value);
  }
  get resultAsync() {
    return Promise.resolve(this.value);
  }
  cancel() {}
}
export { StaticLoadingState };