class BaseReducers {
  /**
   * Add an element to the end of a list
   */
  static addition() {
    return (data, state) => [...state, data];
  }
  /**
   * Update an element in a list
   * Element is targeted based on ID
   */
  static updateById() {
    return (data, state) => {
      const index = state.findIndex(x => x.id === data.id);
      if (index < 0) return state;
      const val = state[index];
      const newState = [...state];
      newState.splice(index, 1, {
        ...val,
        ...data
      });
      return newState;
    };
  }
  /**
   * Update an element in a list
   * Element is targeted based on given selector
   */
  static updateElement(selector) {
    return (data, state) => {
      const index = state.findIndex(x => selector(x) === selector(data));
      if (index < 0) return state;
      const val = state[index];
      const newState = [...state];
      newState.splice(index, 1, {
        ...val,
        ...data
      });
      return newState;
    };
  }
  /**
   * Remove an element from a list
   * Element is targeted based on ID
   */
  static deleteById() {
    return (data, state) => {
      const index = state.findIndex(x => x.id === data);
      if (index < 0) return state;
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    };
  }
  /**
   * Remove an element from a list
   * Element is targeted based on given selector
   */
  static deleteElement(selector) {
    return (data, state) => {
      const index = state.findIndex(x => selector(x) === data);
      if (index < 0) return state;
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    };
  }
  /**
   * Update an element in a list
   * Element is targeted based on ID
   * If the element doesn't exist then it's added to the end of the list
   */
  static setById() {
    return (data, state) => {
      const index = state.findIndex(x => x.id === data.id);
      if (index < 0) return [...state, data];
      const val = state[index];
      const newState = [...state];
      newState.splice(index, 1, {
        ...val,
        ...data
      });
      return newState;
    };
  }
  /**
   * Update an element in a list
   * Element is targeted based on given selector
   * If the element doesn't exist then it's added to the end of the list
   */
  static setElement(selector) {
    return (data, state) => {
      const index = state.findIndex(x => selector(x) === selector(data));
      if (index < 0) return [...state, data];
      const val = state[index];
      const newState = [...state];
      newState.splice(index, 1, {
        ...val,
        ...data
      });
      return newState;
    };
  }
  /**
   * Replace an element in a list
   * Element is targeted based on ID
   * @param add - If true the element is added when no match is found
   */
  static replaceById(add = false) {
    return (data, state) => {
      const index = state.findIndex(x => x.id === data.id);
      if (index < 0) {
        if (add) return [...state, data];else return state;
      }
      const newState = [...state];
      newState.splice(index, 1, data);
      return newState;
    };
  }
  /**
   * Replace an element in a list
   * Element is targeted based on given selector
   * @param selector - Selector for targeting element
   * @param add - If true the element is added when no match is found
   */
  static replaceElement(selector, add = false) {
    return (data, state) => {
      const index = state.findIndex(x => selector(x) === selector(data));
      if (index < 0) {
        if (add) return [...state, data];else return state;
      }
      const newState = [...state];
      newState.splice(index, 1, data);
      return newState;
    };
  }
}
export { BaseReducers };