import { BehaviorSubject, switchMap, from, of, distinctUntilChanged, Subject, concatMap, EMPTY, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { cache } from '@juulsgaard/rxjs-tools';
class CacheDatabaseContext {
  constructor(adapter, databaseId) {
    this.adapter = adapter;
    this.databaseId = databaseId;
    this._enabled$ = new BehaviorSubject(true);
    this.enabled$ = this._enabled$.asObservable();
    this.available$ = this._enabled$.pipe(switchMap(enabled => enabled ? from(this._isAvailable()) : of(false)), distinctUntilChanged(), cache());
    this.setupQueue();
  }
  get error() {
    return this._error ?? `Can't access IndexedDB`;
  }
  get enabled() {
    return this._enabled$.value;
  }
  reset() {
    this.setupQueue();
  }
  /** Enable the database for use */
  enable() {
    if (this.enabled) return;
    this._enabled$.next(true);
  }
  /** Disable the database */
  disable() {
    if (!this.enabled) return;
    this._enabled$.next(false);
  }
  async isAvailable() {
    if (!this.enabled) return false;
    return await this._isAvailable();
  }
  async _isAvailable() {
    if (this._available != null) return await this._available;
    this._available = this.adapter.isAvailable(err => this._error = err);
    this._available.then(available => {
      if (available) return;
      console.warn(`Database "${this.databaseId}" is not available`, this.error);
    });
    return await this._available;
  }
  async verifyAvailable() {
    if (await this.isAvailable()) return;
    throw Error(this.error);
  }
  setupQueue() {
    this.transactionSub?.unsubscribe();
    this.transactionQueue?.complete();
    this.transactionQueue = new Subject();
    this.transactionSub = this.transactionQueue.pipe(concatMap(x => x.run())).subscribe();
  }
  async init() {
    if (!this.enabled) throw Error("The database is currently disabled");
    if (this.initialising) return await this.initialising;
    this.initialising = this._init();
    return await this.initialising;
  }
  async _init() {
    await this.verifyAvailable();
    await this.adapter.createDatabase(this.databaseId);
  }
  async delete() {
    if (this.initialising) await this.initialising;else await this.verifyAvailable();
    this.transactionSub?.unsubscribe();
    await this.adapter.deleteDatabase(this.databaseId);
    this.reset();
    this.initialising = void 0;
  }
  getChunk(chunkId, version) {
    return new CacheChunkContext(this.adapter, this.databaseId, chunkId, version, this);
  }
  async useTransaction(use, readonly, cancel$) {
    await this.init();
    return await new Promise((resolve, reject) => {
      const action = new CacheTransaction(() => this.adapter.startTransaction(this.databaseId, readonly), async trx => {
        try {
          const result = await use(trx);
          resolve(result);
        } catch (e) {
          reject(e);
        } finally {
          await trx.dispose();
        }
      });
      cancel$?.subscribe(() => action.cancel());
      this.transactionQueue?.next(action);
    });
  }
}
class CacheTransaction {
  constructor(trxFactory, action) {
    this.trxFactory = trxFactory;
    this.action = action;
    this.cancelled = false;
  }
  cancel() {
    this.cancelled = true;
    this.trx?.revert();
  }
  run() {
    if (this.cancelled) return EMPTY;
    return from(this.trxFactory()).pipe(
    // Store transaction
    tap(trx => this.trx = trx),
    // Execute action
    map(trx => this.action(trx)),
    // Map result
    switchMap(result => result instanceof Promise ? from(result) : of(result)), tap({
      // Cancel if the observable is unsubscribed from before completion
      unsubscribe: () => this.cancel(),
      // remove adapter when transaction has finished
      finalize: () => this.trx = void 0
    }));
  }
}
class CacheChunkContext {
  constructor(adapter, databaseId, chunkId, version, database) {
    this.adapter = adapter;
    this.databaseId = databaseId;
    this.chunkId = chunkId;
    this.version = version;
    this.database = database;
    this.transactionTriggers = /* @__PURE__ */new Set();
    this.available$ = this.database.available$;
  }
  async isAvailable() {
    return await this.database.isAvailable();
  }
  reset() {
    this.transactionTriggers.forEach(x => x.next());
    this.transactionTriggers.clear();
  }
  async init() {
    await this.database.init();
    if (this.initialising) return await this.initialising;
    this.initialising = this._init(this.version);
    return await this.initialising;
  }
  async _init(version) {
    await this.database.useTransaction(async trx => {
      const transaction = new CacheTransactionContext(this.chunkId, trx);
      await transaction.initChunk(version);
      await transaction.commit();
    }, false);
  }
  async useTransaction(use, readonly) {
    await this.init();
    const cancel$ = new Subject();
    this.transactionTriggers.add(cancel$);
    return await this.database.useTransaction(trx => {
      try {
        const transaction = new CacheTransactionContext(this.chunkId, trx);
        return use(transaction);
      } finally {
        cancel$.complete();
        this.transactionTriggers.delete(cancel$);
      }
    }, readonly, cancel$);
  }
}
class CacheTransactionContext {
  constructor(chunkId, adapter) {
    this.chunkId = chunkId;
    this.adapter = adapter;
    this.changes = false;
  }
  async initChunk(version) {
    const currentVersion = await this.adapter.getChunkVersion(this.chunkId);
    if (currentVersion === version) return;
    if (version !== void 0) await this.adapter.deleteChunk(this.chunkId);
    await this.adapter.createChunk(this.chunkId, version);
  }
  //<editor-fold desc="Values">
  async readValue(id) {
    return await this.adapter.readValue(this.chunkId, id);
  }
  async readValuesWithTag(tag) {
    return await this.adapter.readValuesWithTag(this.chunkId, tag);
  }
  async readAllValues() {
    return await this.adapter.readAllValues(this.chunkId);
  }
  async addValue(id, value, tags) {
    await this.adapter.addValue(this.chunkId, id, value, tags ?? []);
    this.changes = true;
  }
  async updateValue(id, value) {
    await this.adapter.updateValue(this.chunkId, id, value);
    this.changes = true;
  }
  async updateValueAge(id, newAge) {
    await this.adapter.updateValueAge(this.chunkId, id, newAge);
    this.changes = true;
  }
  async deleteValue(id) {
    await this.adapter.deleteValue(this.chunkId, id);
    this.changes = true;
  }
  async deleteTag(tag) {
    await this.adapter.deleteTag(tag);
    this.changes = true;
  }
  //</editor-fold>
  //<editor-fold desc="Transaction state">
  async commit() {
    await this.adapter.commit();
    this.changes = false;
  }
  async revert() {
    await this.adapter.revert();
    this.changes = false;
  }
  async dispose() {
    if (!this.changes) return;
    await this.revert();
  }
  //</editor-fold>
}
export { CacheChunkContext, CacheDatabaseContext };