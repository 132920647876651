class ListMapCache {
  constructor() {
    this._cache = /* @__PURE__ */new Map();
  }
  mapList(list, mapFunc) {
    const result = [];
    const newCache = /* @__PURE__ */new Map();
    for (let item of list) {
      const mapped = this._cache.has(item) ? this._cache.get(item) : mapFunc(item);
      result.push(mapped);
      newCache.set(item, mapped);
    }
    this._cache.clear();
    this._cache = newCache;
    return result;
  }
  clear() {
    this._cache.clear();
  }
  dispose() {
    this.clear();
  }
}
export { ListMapCache };