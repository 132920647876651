import { ListMapCache, isBool } from '@juulsgaard/ts-tools';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
function mapList(mapFunc) {
  return source => source.pipe(map(list => list.map(mapFunc)));
}
function filterList(filterFunc) {
  return source => source.pipe(map(list => list.filter(filterFunc)));
}
function mapListChanged(mapFunc, cache = true) {
  return source => new Observable(subscriber => {
    const mapCache = new ListMapCache();
    let cacheSub;
    let useCache = true;
    if (isBool(cache)) {
      useCache = cache;
    } else {
      cacheSub = cache.subscribe({
        next: x => {
          if (useCache && !x) mapCache.clear();
          useCache = x;
        }
      });
    }
    const sub = source.subscribe({
      error: e => subscriber.error(e),
      complete: () => {
        mapCache.clear();
        subscriber.complete();
      },
      next: list => {
        subscriber.next(useCache ? mapCache.mapList(list, mapFunc) : list.map(mapFunc));
      }
    });
    return () => {
      sub.unsubscribe();
      cacheSub?.unsubscribe();
      mapCache.dispose();
    };
  });
}
export { filterList, mapList, mapListChanged };