import { Subject, from, Observable } from 'rxjs';
import { isSubscribable } from '../util/type-guards.js';
class BaseAsyncValueMapper {
  constructor(onValue, onError) {
    this.onValue = onValue;
    this.onError = onError;
    this.disposed = false;
    this._value$ = new Subject();
    this.value$ = this._value$.asObservable();
    this._error$ = new Subject();
    this.error$ = this._error$.asObservable();
  }
  setValue(value) {
    this._value$.next(value);
    this.onValue?.(value);
  }
  setError() {
    this._error$.next();
    this.onError?.();
  }
  /**
   * Update the mapper with a new input value
   * @param value
   * @returns emitted - True is a value was synchronously emitted
   */
  update(value) {
    if (this.disposed) return;
    this.sub?.unsubscribe();
    if (value instanceof Promise) {
      value = from(value);
    }
    if (value instanceof Observable || isSubscribable(value)) {
      let emitted = false;
      const sub = this.value$.subscribe(() => emitted = true);
      this.sub = this.mapObservable(value);
      sub.unsubscribe();
      return emitted;
    }
    this.setValue(value);
    return true;
  }
  reset() {
    this.sub?.unsubscribe();
  }
  dispose() {
    this.reset();
    this.disposed = true;
  }
}
class AsyncValueMapper extends BaseAsyncValueMapper {
  mapObservable(value$) {
    return value$.subscribe({
      next: x => this.setValue(x),
      error: () => this.setError()
    });
  }
}
class AsyncValueFallbackMapper extends BaseAsyncValueMapper {
  constructor(fallback, onValue, onError) {
    super(onValue, onError);
    this.fallback = fallback;
  }
  mapObservable(value$) {
    let emitted = false;
    const sub = value$.subscribe({
      next: x => {
        if (!emitted) emitted = true;
        this.setValue(x);
      },
      error: () => {
        this.setError();
      }
    });
    if (!emitted) this.setValue(this.fallback);
    return sub;
  }
}
export { AsyncValueFallbackMapper, AsyncValueMapper };