function arrToSet(arr, map) {
  if (!map) return new Set(arr);
  const result = /* @__PURE__ */new Set();
  let index = 0;
  for (let val of arr) {
    result.add(map(val, index++));
  }
  return result;
}
function cloneSet(set) {
  return new Set(set);
}
export { arrToSet, cloneSet };