import { objectReducerScope, applyScopedObjectReducer, listReducerScope } from '../models/reducer-scope.js';
import { DeferredCommand } from '../commands/deferred-command.js';
class DeferredCommandOptionConfig {
  constructor(options) {
    this.options = options;
  }
  /**
   * Don't show errors to the user
   */
  hideErrors() {
    this.options.showError = false;
    return this;
  }
  /**
   * Define a custom error message
   * @param message - The message
   */
  withErrorMessage(message) {
    this.options.errorMessage = message;
    return this;
  }
  /**
   * Define a message to show the user on success
   * @param message - The message / message factory
   */
  withSuccessMessage(message) {
    this.options.successMessage = message;
    return this;
  }
  /**
   * Add an action that will be run after a successful command action
   * @param effect
   */
  withAfterEffect(effect) {
    this.options.afterEffect = effect;
    return this;
  }
}
class DeferredCommandObjectConfig extends DeferredCommandOptionConfig {
  constructor(context, options, scope, path) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.path = path;
  }
  /**
   * Target a property on the object
   * @param key - The property name
   * @param coalesce - A default value to use if property isn't found
   */
  targetProp(key, coalesce) {
    const path = [...this.path, key.toString()];
    return new DeferredCommandObjectConfig(this.context, this.options, objectReducerScope(this.scope, key, path, coalesce), path);
  }
  /**
   * Target a list property on the object
   * @param key - The property name
   * @param create - Add list if it doesn't exist
   */
  targetList(key, create = false) {
    const path = [...this.path, key.toString()];
    return new DeferredCommandListConfig(this.context, this.options, objectReducerScope(this.scope, key, path, create ? [] : void 0), path);
  }
  /**
   * Target a unit array property on the object
   * @param key - The property name
   * @param create - Add list if it doesn't exist
   */
  targetArray(key, create = false) {
    const path = [...this.path, key.toString()];
    return new DeferredCommandArrayConfig(this.context, this.options, objectReducerScope(this.scope, key, path, create ? [] : void 0), path);
  }
  /**
   * Apply a modification to the payload before the reducer
   * @param func - The data modification
   */
  modify(func) {
    return new DeferredCommandObjectDataConfig(this.context, this.options, this.scope, func);
  }
  /**
   * Define the reducer for the active scope
   * @param reducer
   */
  withReducer(reducer) {
    return new DeferredCommand(this.context, this.options, (root, data) => this.scope(root, data, state => applyScopedObjectReducer(state, reducer(data, state))));
  }
}
class DeferredCommandArrayConfig extends DeferredCommandOptionConfig {
  constructor(context, options, scope, path) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.path = path;
  }
  /**
   * Apply a modification to the payload before the reducer
   * @param func - The data modification
   */
  modify(func) {
    return new DeferredCommandListDataConfig(this.context, this.options, this.scope, func);
  }
  /**
   * Define the reducer for the active scope
   * @param reducer
   */
  withReducer(reducer) {
    return new DeferredCommand(this.context, this.options, (root, data) => this.scope(root, data, state => reducer(data, state)));
  }
}
class DeferredCommandListConfig extends DeferredCommandArrayConfig {
  /**
   * Target a list item in the list
   * @param selector - The selector for the list item
   * @param coalesce - A default value to append if item isn't found
   */
  targetItem(selector, coalesce) {
    const path = [...this.path, "[]"];
    return new DeferredCommandObjectConfig(this.context, this.options, listReducerScope(this.scope, payload => selector(payload, payload), path, coalesce), path);
  }
}
class DeferredCommandObjectDataConfig extends DeferredCommandOptionConfig {
  constructor(context, options, scope, modify) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.modify = modify;
  }
  /**
   * Define the reducer for the active scope
   * @param reducer
   */
  withReducer(reducer) {
    return new DeferredCommand(this.context, this.options, (root, data) => this.scope(root, data, state => applyScopedObjectReducer(state, reducer(this.modify(data, state), state))));
  }
}
class DeferredCommandListDataConfig extends DeferredCommandOptionConfig {
  constructor(context, options, scope, modify) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.modify = modify;
  }
  /**
   * Define the reducer for the active scope
   * @param reducer
   */
  withReducer(reducer) {
    return new DeferredCommand(this.context, this.options, (root, data) => this.scope(root, data, state => reducer(this.modify(data, state), state)));
  }
}
export { DeferredCommandObjectConfig };