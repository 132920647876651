class WhereItem {
  static IdMatchesPayload(payloadId) {
    const getPayloadId = payloadId ?? (x => x);
    return (data, payload) => {
      const id = getPayloadId(payload);
      return element => element.id === id;
    };
  }
  static MatchesPayload(elementId, payloadId) {
    const getPayloadId = payloadId ?? (x => x);
    const getElementId = elementId ?? (x => x);
    return (data, payload) => {
      const id = getPayloadId(payload);
      return element => getElementId(element) === id;
    };
  }
  static IdMatchesData(dataId) {
    const getDataId = dataId ?? (x => x);
    return (data, payload) => {
      const id = getDataId(data);
      return element => element.id === id;
    };
  }
  static IdMatchesValue(id) {
    return () => element => element.id === id;
  }
  static MatchesData(elementId, dataId) {
    const getDataId = dataId ?? (x => x);
    const getElementId = elementId ?? (x => x);
    return data => {
      const id = getDataId(data);
      return element => getElementId(element) === id;
    };
  }
}
export { WhereItem };