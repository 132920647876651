import { logSuccessfulAction, logFailedAction } from '../models/logging.js';
import { tap, switchMap, EMPTY, startWith } from 'rxjs';
import { QueueAction } from '../models/queue-action.js';
import { AsyncPayloadCommand } from '../models/base-commands.js';
import { Loading } from '@juulsgaard/rxjs-tools';
class DeferredCommand extends AsyncPayloadCommand {
  constructor(context, options, reducer) {
    super(context);
    this.options = options;
    this.reducer = reducer;
    this.isSync = true;
  }
  get initialLoad() {
    return false;
  }
  /**
   * Dispatch the command and return a LoadingState to monitor command progress
   * @param payload - The command payload
   */
  observe(payload) {
    this.context.startLoad(this, void 0);
    const state = Loading.Delayed(() => this.options.action(payload));
    const execute = () => {
      const startedAt = Date.now();
      return state.trigger$.pipe(
      // Log success / error
      tap({
        next: result => this.onSuccess(payload, result, startedAt),
        error: error => this.onFailure(payload, error, startedAt)
      }), switchMap(() => EMPTY),
      // Start with reducer
      startWith(state2 => this.reducer(state2, payload)));
    };
    this.context.applyCommand(new QueueAction(this, execute, () => state.cancel(), false, true));
    state.then(data => {
      this.context.endLoad(this, void 0);
      setTimeout(() => this.options.afterEffect?.(data, payload));
    }).catch(e => this.context.failLoad(this, e, void 0));
    return state;
  }
  /**
   * Handle a successful action
   * @param payload
   * @param result
   * @param startedAt
   * @private
   */
  onSuccess(payload, result, startedAt) {
    if (this.options.successMessage) {
      const message = this.options.successMessage instanceof Function ? this.options.successMessage(result, payload) : this.options.successMessage;
      this.context.displaySuccess(message);
    }
    if (!this.context.isProduction) logSuccessfulAction(this.name, void 0, startedAt, payload, result);
  }
  /**
   * Handle a failed action
   * @param payload
   * @param error
   * @param startedAt
   * @private
   */
  onFailure(payload, error, startedAt) {
    if (this.options.showError) {
      this.context.displayError(this.options.errorMessage, error);
    }
    if (!this.context.isProduction) logFailedAction(this.name, startedAt, payload, error);
  }
  /**
   * Emit the command with no status returned
   * @param payload
   */
  emit(payload) {
    this.observe(payload);
  }
  /**
   * Emit the command with a Promise status
   * @param payload
   */
  emitAsync(payload) {
    return this.observe(payload).resultAsync;
  }
}
export { DeferredCommand };