import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable, tap} from "rxjs";
import {distinctUntilChanged, filter} from "rxjs/operators";
import {filterAsync, permanentCache} from "@juulsgaard/rxjs-tools";
import {isObject, lowerFirst, mapObj} from "@juulsgaard/ts-tools";
import {LocalStorage} from "@lib/services/local-storage.service";
import {SnackbarService} from "@juulsgaard/ngx-material";
import {IdentityDatabase} from "@lib/databases/identity.database";
import {RouteService} from "@juulsgaard/ngx-tools";

@Injectable({providedIn: 'root'})
export class IdentityService {

  private static readonly OLD_TOKEN_KEY = 'last-id-token';

  private _token = new BehaviorSubject<string | undefined>(undefined);
  identityToken$: Observable<string>;

  constructor(
    private locationService: RouteService,
    private zone: NgZone,
    private database: IdentityDatabase,
    private storage: LocalStorage,
    private snacks: SnackbarService
  ) {
    this.identityToken$ = this._token.pipe(
      tap({subscribe: () => this.init()}),
      filter((x): x is string => !!x),
      distinctUntilChanged(),
      filterAsync(token => this.handleToken(token)),
      permanentCache()
    );
  }

  start() {
    if (this._token.observed) return;
    this.identityToken$.subscribe();
  }

  /**
   * Handles a new token
   * Resets Database on new token
   * @param token
   * @return valid - Says if the token is valid
   */
  private async handleToken(token: string): Promise<boolean> {
    const data = this.parseIdToken(token);

    if (token && !data) {
      console.warn('Received an invalid token. Token was not recognised as a JWT token.');
    }

    const id = data ? data.uid ?? data.email ?? data.username ?? data.sub : undefined;

    if (data && !id) {
      console.warn('Received an invalid token. Identifying information not found in JWT payload.');
    }

    if (!this.storage.isAvailable()) {
      this.snacks.warning(this.storage.error, `Limited Access`);

      if (await this.database.isAvailable()) {
        await this.database.delete();
      }

      return !!id;
    }

    if (!await this.database.isAvailable()) {
      this.snacks.warning(this.database.error, `Limited Access`);
      return !!id;
    }

    // No ID found, reset
    if (!id) {
      await this.database.delete();
      localStorage.removeItem(IdentityService.OLD_TOKEN_KEY);
      console.log(`Cache was reset because the user doesn't have an ID`);
      return false;
    }

    const oldId = localStorage.getItem(IdentityService.OLD_TOKEN_KEY);

    // Reset the DB if token is new
    if (oldId !== id) {
      await this.database.delete();
      localStorage.setItem(IdentityService.OLD_TOKEN_KEY, id);
      console.log(`Cache was reset because the user ID has changed`);
    }

    return true;
  }

  private parseIdToken(token: string): IdToken | undefined {
    if (!token) return undefined;
    const split = token.split('.');
    if (split.length < 3) return undefined;
    const base64Url = split[1]!;
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    return JSON.parse(jsonPayload, (key, value) => {
      if (!isObject(value)) return value;
      return mapObj(value as Record<string, any>, x => x, (_, key) => lowerFirst(key));
    });
  }

  //<editor-fold desc="Init Logic">
  private init() {
    if (window.parent !== window) this.initIframe()
    else this.initQuery();
  }

  private initIframe() {
    this.zone.runOutsideAngular(() => {
      window.addEventListener('message', event => {
        if (!event.data.token) return;
        this.zone.run(() => this._token.next(event.data.token));
      });
    })

    this.requestToken();
  }

  private requestToken() {
    if (window.parent === window) return;
    window.parent.postMessage('app-loaded', '*');
  }

  private initQuery() {
    this.locationService.getQuery$('token').subscribe(x => this._token.next(x ?? undefined));
  }

  //</editor-fold>
}

interface IdToken {
  uid?: string;
  email?: string;
  username?: string;
  sub?: string;
}
