import { distinctUntilChanged } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheChunk } from './cache-chunk.js';
import { parseIdMap } from '../lib/id-map.js';
import { persistentCache } from '@juulsgaard/rxjs-tools';
class CacheConfig {
  constructor(chunkId, version, databaseContext, states$) {
    this.chunkId = chunkId;
    this.version = version;
    this.databaseContext = databaseContext;
    this.states$ = states$;
  }
  /**
   * Track cache as multiple units with an ID
   * @param chunks - The mapping for the cache chunks
   */
  withChunks(chunks) {
    const context = this.databaseContext.getChunk(this.chunkId, this.version);
    const chunks$ = this.states$.pipe(map(state$ => state$.pipe(map(chunks), persistentCache(5e3))));
    return new CacheChunkConfig(context, chunks$);
  }
  /**
   * Track cache as multiple units with an ID.
   * Uses a scope to limit the state changes that trigger a cache re-evaluation.
   * @param scope - The change detection scope for the cache
   * @param chunks - The mapping for the cache chunks
   */
  withScopedChunks(scope, chunks) {
    const context = this.databaseContext.getChunk(this.chunkId, this.version);
    const chunks$ = this.states$.pipe(map(state$ => state$.pipe(map(scope), distinctUntilChanged(), map(chunks), persistentCache(5e3))));
    return new CacheChunkConfig(context, chunks$);
  }
  /**
   * Track cache as single object
   * @param chunk - Mapping for the cache value
   * @param getId - Optionally give the value an ID - Will default to use a 'global' ID
   */
  singleChunk(chunk, getId) {
    getId ?? (getId = () => "global");
    const context = this.databaseContext.getChunk(this.chunkId, this.version);
    const chunks$ = this.states$.pipe(map(state$ => state$.pipe(map(chunk), distinctUntilChanged(), map(x => x != null ? [x] : []), persistentCache(5e3))));
    return new CacheChunk(chunks$, context, getId);
  }
}
class CacheChunkConfig {
  constructor(context, chunks$) {
    this.context = context;
    this.chunks$ = chunks$;
  }
  withId(getId, getTags) {
    return new CacheChunk(this.chunks$, this.context, parseIdMap(getId), getTags);
  }
}
export { CacheConfig };