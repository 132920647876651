import { Observable } from 'rxjs';
function disposable() {
  return source => new Observable(subscriber => {
    let oldValue;
    const sub = source.subscribe({
      next: val => {
        oldValue?.dispose();
        oldValue = val;
        subscriber.next(val);
      },
      error: subscriber.error,
      complete: subscriber.complete
    });
    return () => {
      oldValue?.dispose();
      sub.unsubscribe();
    };
  });
}
export { disposable };