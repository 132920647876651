import { Subject, Observable, Subscription, from } from 'rxjs';
import { isSubscribable } from '../util/type-guards.js';
class BaseAsyncObjectMapper {
  constructor() {
    this.disposed = false;
    this._values$ = new Subject();
    this.values$ = this._values$.asObservable();
  }
  update(values) {
    if (this.disposed) return false;
    this.current?.forEach(x => x.reset());
    const newCells = /* @__PURE__ */new Map();
    for (let prop in values) {
      const value = values[prop];
      const [key, cell] = this.getCell(value, prop);
      newCells.set(key, cell);
    }
    this.current?.forEach(x => x.dispose());
    this.current = newCells;
    const emitted = this.evaluate(newCells.values());
    this.current.forEach(c => c.onUpdate(() => this.evaluate(newCells.values())));
    return emitted;
  }
  getCell(value, prop) {
    if (value instanceof Promise) {
      return [value, this.handleAsync(value, prop)];
    }
    if (value instanceof Observable || isSubscribable(value)) {
      return [value, this.handleAsync(value, prop)];
    }
    return [prop, this.handleSync(value, prop)];
  }
  handleAsync(val, prop) {
    const oldCell = this.current?.get(val);
    if (oldCell) {
      oldCell.prop = prop;
      this.current.delete(val);
      return oldCell;
    }
    return new ValueCell(val, prop);
  }
  handleSync(val, prop) {
    return new ValueCell(val, prop);
  }
  setValue(value) {
    this._values$.next(value);
  }
  reset() {
    this.current?.forEach(x => x.dispose());
    this.current = void 0;
  }
  dispose() {
    this.disposed = true;
    this.reset();
  }
}
class AsyncObjectFallbackMapper extends BaseAsyncObjectMapper {
  constructor(fallback) {
    super();
    this.fallback = fallback;
  }
  evaluate(cells) {
    const values = {};
    for (let cell of cells) {
      values[cell.prop] = cell.hasValue ? cell.value : this.fallback;
    }
    this.setValue(values);
    return true;
  }
}
class AsyncObjectMapper extends BaseAsyncObjectMapper {
  constructor() {
    super();
  }
  evaluate(cells) {
    const values = {};
    for (let cell of cells) {
      if (!cell.hasValue) return false;
      values[cell.prop] = cell.value;
    }
    this.setValue(values);
    return true;
  }
}
class ValueCell {
  constructor(value, prop) {
    this.prop = prop;
    this.disposed = false;
    this.hasValue = false;
    this.update$ = new Subject();
    this.updateSub = new Subscription();
    if (value instanceof Promise) {
      value = from(value);
    }
    if (value instanceof Observable || isSubscribable(value)) {
      this.sub = value.subscribe({
        next: x => this.setVal(x),
        error: () => this.setError()
      });
      return;
    }
    this.setVal(value);
  }
  setVal(val) {
    this.value = val;
    this.hasValue = true;
    this.update$.next();
  }
  setError() {
    this.value = void 0;
    this.hasValue = false;
    this.update$.next();
  }
  onUpdate(onUpdate) {
    if (this.disposed) return;
    this.updateSub.add(this.update$.subscribe(onUpdate));
  }
  reset() {
    this.updateSub.unsubscribe();
  }
  dispose() {
    this.disposed = true;
    this.reset();
    this.sub?.unsubscribe();
  }
}
export { AsyncObjectFallbackMapper, AsyncObjectMapper };