import {Environment} from "@environments/types";

export const environment: Environment = {
  production: true,
  serviceWorker: true,
  showWip: true,
  loginRedirectToken: `2ed7e3b95d084102376901b29e1c660544b6fd7e9f9c310e2e0e97bff3aa5b0f`,
  apiHost: 'https://dev.api.consensus-labs.com/',
  storageUrl: 'https://media-dev.consensus-labs.com/'
};
