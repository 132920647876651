import { objectReducerScope, applyScopedObjectReducer, listReducerScope } from '../models/reducer-scope.js';
import { PlainCommand } from '../commands/plain-command.js';
class PlainCommandObjectConfig {
  constructor(context, scope, path) {
    this.context = context;
    this.scope = scope;
    this.path = path;
  }
  /**
   * Target a property on the object
   * @param key - The property name
   * @param coalesce - A default value to use if property isn't found
   */
  targetProp(key, coalesce) {
    const path = [...this.path, key.toString()];
    return new PlainCommandObjectConfig(this.context, objectReducerScope(this.scope, key, path, coalesce), path);
  }
  /**
   * Target a list property on the object
   * @param key - The property name
   * @param create - Add list if it doesn't exist
   */
  targetList(key, create = false) {
    const path = [...this.path, key.toString()];
    return new PlainCommandListConfig(this.context, objectReducerScope(this.scope, key, path, create ? [] : void 0), path);
  }
  /**
   * Target a unit array property on the object
   * @param key - The property name
   * @param create - Add list if it doesn't exist
   */
  targetArray(key, create = false) {
    const path = [...this.path, key.toString()];
    return new PlainCommandArrayConfig(this.context, objectReducerScope(this.scope, key, path, create ? [] : void 0), path);
  }
  /**
   * Define the reducer for the active scope
   * @param reducer
   */
  withReducer(reducer) {
    return new PlainCommand(this.context, (root, data) => this.scope(root, data, state => applyScopedObjectReducer(state, reducer(data, state))));
  }
}
class PlainCommandArrayConfig {
  constructor(context, scope, path) {
    this.context = context;
    this.scope = scope;
    this.path = path;
  }
  /**
   * Define the reducer for the active scope
   * @param reducer
   */
  withReducer(reducer) {
    return new PlainCommand(this.context, (root, data) => this.scope(root, data, state => reducer(data, state)));
  }
}
class PlainCommandListConfig extends PlainCommandArrayConfig {
  /**
   * Target a list item in the list
   * @param selector - The selector for the list item
   * @param coalesce - A default value to append if item isn't found
   */
  targetItem(selector, coalesce) {
    const path = [...this.path, "[]"];
    return new PlainCommandObjectConfig(this.context, listReducerScope(this.scope, data => selector(data, data), path, coalesce), path);
  }
}
export { PlainCommandObjectConfig };