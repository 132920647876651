import { ActionCommand } from '../commands/action-command.js';
import { objectReducerScope, createActionReducerCoalesce, applyScopedObjectReducer, listReducerScope } from '../models/reducer-scope.js';
class ActionCommandOptionConfig {
  constructor(options) {
    this.options = options;
  }
  /**
   * Marks the command as an initial load
   * This command can only be run once
   */
  isInitial(requestId) {
    this.options.initialLoad = true;
    this.options.requestId = requestId ?? this.options.requestId;
    return this;
  }
  /**
   * Cancels requests that happen while one of similar type / id is ongoing
   */
  cancelConcurrent(requestId) {
    this.options.cancelConcurrent = true;
    this.options.requestId = requestId ?? this.options.requestId;
    return this;
  }
  /**
   * Assign a request id to individual actions
   * @param requestId - Request Id generator
   */
  withRequestId(requestId) {
    this.options.requestId = requestId;
    return this;
  }
  /**
   * Don't show errors to the user
   */
  hideErrors() {
    this.options.showError = false;
    return this;
  }
  /**
   * Define a custom error message
   * @param message - The message
   */
  withErrorMessage(message) {
    this.options.errorMessage = message;
    return this;
  }
  /**
   * Define a message to show the user on success
   * @param message - The message / message factory
   */
  withSuccessMessage(message) {
    this.options.successMessage = message;
    return this;
  }
  /**
   * Define a parser to apply to the data before the reducer
   * @param parser - A parser that modifies the data
   */
  withParser(parser) {
    this.options.modify = parser;
    return this;
  }
  /**
   * Apply all actions of this type via a queue
   * New actions of this type won't be emitted until the previous ones have completed
   */
  withQueue() {
    this.options.queue = true;
    return this;
  }
  /**
   * Add an action that will be run after a successful command action
   * @param effect
   * @param beforeReducer - Make the effect execute before the reducer
   */
  withAfterEffect(effect, beforeReducer = false) {
    if (beforeReducer) this.options.preEffect = effect;else this.options.afterEffect = effect;
    return this;
  }
  withRetries(retries, delay = 1e3, backoff = 0) {
    if (Array.isArray(retries)) {
      this.options.retries = retries;
    } else {
      this.options.retries = Array.from(new Array(retries), (_, i) => delay + i * backoff);
    }
    return this;
  }
}
class ActionCommandObjectConfig extends ActionCommandOptionConfig {
  constructor(context, options, scope, path) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.path = path;
  }
  /**
   * Target a property on the object
   * @param key - The property name
   * @param coalesce - A default value to use if property isn't found
   */
  targetProp(key, coalesce) {
    const path = [...this.path, key.toString()];
    return new ActionCommandObjectConfig(this.context, this.options, objectReducerScope(this.scope, key, path, createActionReducerCoalesce(coalesce)), path);
  }
  /**
   * Target a list property on the object
   * @param key - The property name
   * @param create - Add list if it doesn't exist
   */
  targetList(key, create = false) {
    const path = [...this.path, key.toString()];
    return new ActionCommandListConfig(this.context, this.options, objectReducerScope(this.scope, key, path, create ? [] : void 0), path);
  }
  /**
   * Target a unit array property on the object
   * @param key - The property name
   * @param create - Add list if it doesn't exist
   */
  targetArray(key, create = false) {
    const path = [...this.path, key.toString()];
    return new ActionCommandArrayConfig(this.context, this.options, objectReducerScope(this.scope, key, path, create ? [] : void 0), path);
  }
  /**
   * Apply a modification to the payload / data before the reducer
   * @param func - The data modification
   */
  modify(func) {
    return new ActionCommandObjectDataConfig(this.context, this.options, this.scope, func);
  }
  withReducer(reducer) {
    return new ActionCommand(this.context, this.options, (root, data, payload) => this.scope(root, {
      data,
      payload
    }, state => applyScopedObjectReducer(state, reducer(data, state, payload))));
  }
}
class ActionCommandArrayConfig extends ActionCommandOptionConfig {
  constructor(context, options, scope, path) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.path = path;
  }
  /*  targetSubList(
      selector: Conditional<TElement, any[], (x: TElement, data: TData, payload: TPayload) => boolean>
    ): TElement extends any[] ? ActionCommandListContext<TRoot, TElement, ArrayType<TElement>, TPayload, TData> : never {
      const path = [...this.path, '[]'];
      return new ActionCommandListContext(
        this.options,
        listReducerScope<TRoot, TState, any, ObjectReducerData<TPayload, TData>>(this.scope, (x, {data, payload}) => selector(x, data, payload), path),
        path
      ) as unknown as TElement extends any[] ? ActionCommandListContext<TRoot, TElement, ArrayType<TElement>, TPayload, TData> : never;
    }*/
  /**
   * Apply a modification to the payload / data before the reducer
   * @param func - The data modification
   */
  modify(func) {
    return new ActionCommandListDataConfig(this.context, this.options, this.scope, func);
  }
  withReducer(reducer) {
    return new ActionCommand(this.context, this.options, (root, data, payload) => this.scope(root, {
      data,
      payload
    }, state => reducer(data, state, payload)));
  }
}
class ActionCommandListConfig extends ActionCommandArrayConfig {
  /**
   * Target a list item in the list
   * @param selector - The selector for the list item
   * @param coalesce - A default value to append if item isn't found
   */
  targetItem(selector, coalesce) {
    const path = [...this.path, "[]"];
    return new ActionCommandObjectConfig(this.context, this.options, listReducerScope(this.scope, ({
      data,
      payload
    }) => selector(data, payload), path, createActionReducerCoalesce(coalesce)), path);
  }
}
class ActionCommandObjectDataConfig extends ActionCommandOptionConfig {
  constructor(context, options, scope, modify) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.modify = modify;
  }
  /**
   * Define the reducer for the active scope
   * @param reducer
   */
  withReducer(reducer) {
    return new ActionCommand(this.context, this.options, (root, data, payload) => this.scope(root, {
      data,
      payload
    }, state => applyScopedObjectReducer(state, reducer(this.modify(data, payload, state), state))));
  }
}
class ActionCommandListDataConfig extends ActionCommandOptionConfig {
  constructor(context, options, scope, modify) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.modify = modify;
  }
  /**
   * Define the reducer for the active scope
   * @param reducer
   */
  withReducer(reducer) {
    return new ActionCommand(this.context, this.options, (root, data, payload) => this.scope(root, {
      data,
      payload
    }, state => reducer(this.modify(data, payload, state), state)));
  }
}
export { ActionCommandObjectConfig };