function logSuccessfulAction(name, warning, startedAt, payload, data) {
  const time = startedAt && Date.now() - startedAt;
  const style = "font-weight: bold";
  console.groupCollapsed(`%c${name}${""}`, style);
  console.log("%cPayload: ", style, payload);
  console.log("%cReturn: ", style, data);
  if (time) console.log("%cTime: ", style, time < 1e3 ? "< 1s" : `${time / 1e3}s`);
  console.groupEnd();
}
function logFailedAction(name, startedAt, payload, error) {
  const time = startedAt && Date.now() - startedAt;
  const style = "font-weight: bold; color: red";
  console.groupCollapsed(`%c${name} [!!]`, style);
  console.log("%cPayload: ", style, payload);
  console.log("%cError: ", style, error.message);
  if (time) console.log("%cTime: ", style, time < 1e3 ? "< 1s" : `${time / 1e3}s`);
  if (error.stack) console.log("%cStack: ", style, error.stack);
  if (error.correlationId) console.log("%cCorrelation Id: ", style, error.correlationId);
  console.groupEnd();
}
function logActionInformation(name, info, startedAt, payload) {
  const time = startedAt && Date.now() - startedAt;
  const style = "font-weight: bold; color: dodgerblue";
  console.groupCollapsed(`%c${name}`, style);
  console.log("%cMessage: ", style, info);
  console.log("%cPayload: ", style, payload);
  if (time) console.log("%cTime: ", style, time < 1e3 ? "< 1s" : `${time / 1e3}s`);
  console.groupEnd();
}
export { logActionInformation, logFailedAction, logSuccessfulAction };