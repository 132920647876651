import { map } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs';
import { parseIdMap } from '../lib/id-map.js';
class BaseCommand {}
class StoreCommand extends BaseCommand {
  constructor(context) {
    super();
    this.context = context;
  }
  /**
   * Name of the Command
   */
  get name() {
    return this.context.getCommandName(this);
  }
}
class AsyncCommand extends StoreCommand {
  constructor(context) {
    super(context);
    this.loading$ = context.getLoadState$(this, void 0).pipe(map(x => !!x && x > 0), distinctUntilChanged());
    this.loaded$ = context.getLoadState$(this, void 0).pipe(map(x => x !== void 0), distinctUntilChanged());
    this.failed$ = context.getFailureState$(this, void 0).pipe(distinctUntilChanged());
    this.error$ = context.getErrorState$(this, void 0).pipe(distinctUntilChanged());
  }
  /**
   * Resets the failure state of the command
   * @internal
   */
  resetFailState() {
    this.context.resetFailState(this, void 0);
  }
}
class AsyncPayloadCommand extends AsyncCommand {
  constructor(context, requestId) {
    super(context);
    this.getRequestId = requestId && parseIdMap(requestId);
  }
  loadingById$(payload) {
    if (!this.getRequestId) return this.loading$;
    return this.context.getLoadState$(this, this.getRequestId(payload)).pipe(map(x => !!x && x > 0), distinctUntilChanged());
  }
  loadedById$(payload) {
    if (!this.getRequestId) return this.loaded$;
    return this.context.getLoadState$(this, this.getRequestId(payload)).pipe(map(x => x !== void 0), distinctUntilChanged());
  }
  failedById$(payload) {
    if (!this.getRequestId) return this.failed$;
    return this.context.getFailureState$(this, this.getRequestId(payload)).pipe(distinctUntilChanged());
  }
  errorById$(payload) {
    if (!this.getRequestId) return this.error$;
    return this.context.getErrorState$(this, this.getRequestId(payload)).pipe(distinctUntilChanged());
  }
  /**
   * Resets the failure state of the command and specific request
   * @internal
   */
  resetFailureStateById(payload) {
    if (!this.getRequestId) {
      this.resetFailState();
      return;
    }
    this.context.resetFailState(this, this.getRequestId(payload));
  }
}
export { AsyncCommand, AsyncPayloadCommand, BaseCommand, StoreCommand };