class FutureLoading {
  constructor(value) {
    this.value = value;
  }
}
class FutureValue {
  constructor(value) {
    this.value = value;
  }
}
class FutureError {
  constructor(error, value) {
    this.error = error;
    this.value = value;
  }
}
class FutureEmpty {
  constructor() {}
}
export { FutureEmpty, FutureError, FutureLoading, FutureValue };