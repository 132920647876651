
export abstract class StorageService {

  protected abstract readonly defaultError: string;
  private _available?: boolean;
  private _error?: string;
  get error() {return this._error ?? this.defaultError};

  protected setError(error: string) {
    this._error = error;
  }

  private verifyAvailability() {
    if (this.isAvailable()) return;
    throw Error(this.error);
  }

  isAvailable(): boolean {
    if (this._available != null) return this._available;
    this._available = this._isAvailable();
    return this._available;
  }

  protected abstract _isAvailable(): boolean;

  protected abstract _write(key: string, value: string): void;

  protected abstract _delete(key: string): void;

  protected abstract _read(key: string): string|null;

  write(key: string, value: string) {
    this.verifyAvailability();
    return this._write(key, value);
  }

  tryWrite(key: string, value: string) {
    if (!this.isAvailable()) return false;
    this._write(key, value);
    return true;
  }

  delete(key: string) {
    this.verifyAvailability();
    return this._delete(key);
  }

  tryDelete(key: string) {
    if (!this.isAvailable()) return false;
    this._delete(key);
    return true;
  }

  read(key: string) {
    this.verifyAvailability();
    return this._read(key);
  }

  tryRead(key: string) {
    if (!this.isAvailable()) return undefined;
    return this._read(key);
  }
}
