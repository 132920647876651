import { of, switchMap, isObservable, from, retry, throwError, timer } from 'rxjs';
import { map } from 'rxjs/operators';
function retryAction(action, retries, isCritical, logRetry) {
  return () => {
    return of(action).pipe(map(x => x()), switchMap(x => {
      if (isObservable(x)) return x;
      if (x instanceof Promise) return from(x);
      return of(x);
    }), retry({
      delay: (error, i) => {
        if (isCritical(error)) return throwError(() => error);
        const delay = retries[i];
        if (delay === void 0) return throwError(() => error);
        logRetry?.(i, delay);
        return timer(delay);
      }
    }));
  };
}
export { retryAction };