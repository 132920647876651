import { BehaviorSubject, skip, pairwise, concatMap, from, share, Observable } from 'rxjs';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { cache } from '../operators/cache.js';
class ObservableMap {
  get _map() {
    return this._map$.value;
  }
  get value() {
    return this._map;
  }
  get size() {
    return this._map.size;
  }
  get empty() {
    return this.size <= 0;
  }
  constructor(values) {
    this._map$ = new BehaviorSubject(new Map(values));
    this.value$ = this._map$.asObservable();
    this.updates$ = this.value$.pipe(skip(1));
    this.itemUpdates$ = this.value$.pipe(pairwise(), map(([last, next]) => this.processChanges(last, next)), concatMap(x => from(x)), share());
    this.itemRemoved$ = this.itemUpdates$.pipe(filter(x => x.change === "removed"), share());
    this.itemAdded$ = this.itemUpdates$.pipe(filter(x => x.change === "added"), share());
    this.itemDelta$ = new Observable(subscriber => {
      for (let change of this.processChanges( /* @__PURE__ */new Map(), this.value)) {
        subscriber.next(change);
      }
      return this.itemUpdates$.subscribe(subscriber);
    });
    this.size$ = this.value$.pipe(map(x => x.size), distinctUntilChanged());
    this.empty$ = this.size$.pipe(map(x => x <= 0), distinctUntilChanged());
  }
  [Symbol.iterator]() {
    return this._map[Symbol.iterator]();
  }
  subscribe(observer) {
    return this.value$.subscribe(observer);
  }
  getCopy() {
    return new Map(this._map);
  }
  //<editor-fold desc="Actions">
  filter(whitelist) {
    const length = whitelist && "size" in whitelist ? whitelist.size : whitelist?.length;
    if (!length) {
      return this.clear();
    }
    const whitelistSet = whitelist instanceof Set ? whitelist : new Set(whitelist);
    const map2 = this.getCopy();
    for (let [key] of this._map) {
      if (whitelistSet.has(key)) continue;
      map2.delete(key);
    }
    if (this._map.size !== map2.size) {
      this._map$.next(map2);
      return true;
    }
    return false;
  }
  clear() {
    if (!this._map.size) return false;
    this._map$.next( /* @__PURE__ */new Map());
    return true;
  }
  add(key, value) {
    if (this._map.has(key)) return false;
    const map2 = this.getCopy();
    map2.set(key, value);
    this._map$.next(map2);
    return true;
  }
  set(key, value) {
    if (this._map.has(key) && this._map.get(key) === value) return false;
    const map2 = this.getCopy();
    map2.set(key, value);
    this._map$.next(map2);
    return true;
  }
  delete(key) {
    if (!this._map.has(key)) return false;
    const map2 = this.getCopy();
    map2.delete(key);
    this._map$.next(map2);
    return true;
  }
  deleteRange(values) {
    const map2 = this.getCopy();
    const size = map2.size;
    values.forEach(v => map2.delete(v));
    if (map2.size === size) return false;
    this._map$.next(map2);
    return true;
  }
  /**
   * Toggle a value in the map
   * @param key - The key to toggle
   * @param value - The value to insert if applicable
   * @param state - A forced state (`true` = always add, `false` = always delete)
   * @returns The applied change (`true` = item added, `false` = item removed, `undefined` = nothing changed)
   */
  toggle(key, value, state) {
    if (this._map.has(key)) {
      if (state === true) return void 0;
      const map3 = this.getCopy();
      map3.delete(key);
      this._map$.next(map3);
      return false;
    }
    if (state === false) return void 0;
    const map2 = this.getCopy();
    map2.set(key, value);
    this._map$.next(map2);
    return true;
  }
  has(key) {
    return this._map.has(key);
  }
  has$(key) {
    return this.value$.pipe(map(x => x.has(key)), distinctUntilChanged(), cache());
  }
  get(key) {
    return this._map.get(key);
  }
  get$(key) {
    return this.value$.pipe(map(x => x.get(key)), distinctUntilChanged(), cache());
  }
  modify(modify) {
    const map2 = this.getCopy();
    modify(map2);
    this._map$.next(map2);
  }
  /**
   * Processes changes to individual items
   * @private
   */
  *processChanges(prevMap, nextMap) {
    const old = new Map(prevMap);
    for (let [key, value] of nextMap) {
      if (old.has(key)) {
        old.delete(key);
        continue;
      }
      yield {
        key,
        value,
        change: "added"
      };
    }
    for (let [key, value] of old) {
      yield {
        key,
        value,
        change: "removed"
      };
    }
  }
  //</editor-fold>
}
export { ObservableMap };