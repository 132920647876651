import { Subject, Subscription, first, of, switchMap, EMPTY, concatWith, distinctUntilChanged, tap, mergeWith, from } from 'rxjs';
import { map, pairwise, catchError, concatMap } from 'rxjs/operators';
import { arrToMap } from '@juulsgaard/ts-tools';
class CacheChunk {
  constructor(chunks$, context, getId, getTags) {
    this.chunks$ = chunks$;
    this.context = context;
    this.getId = getId;
    this.getTags = getTags;
    this.ignoreValueChange = /* @__PURE__ */new Set();
    this.manualChanges$ = new Subject();
    this.chunks$.subscribe({
      next: values$ => this.setup(values$),
      complete: () => this.dispose()
    });
  }
  setup(values$) {
    this.ignoreValueChange.clear();
    this.context.reset();
    this.scopedSub?.unsubscribe();
    this.scopedSub = new Subscription();
    let firstVal$ = EMPTY;
    this.scopedSub.add(values$.pipe(first()).subscribe(x => firstVal$ = of(x)));
    const queue$ = this.context.available$.pipe(switchMap(available => !available ? EMPTY : firstVal$.pipe(concatWith(values$), distinctUntilChanged(),
    // Save the latest value for resuming
    tap(x => firstVal$ = of(x)),
    // Split the chunks based on ID
    map(x => arrToMap(x, this.getId)),
    // Get 2 consecutive states at a time
    pairwise(),
    // Find changes between the 2 states
    map(([oldMap, newMap]) => this.mapChanges(oldMap, newMap)),
    // Ignore errors
    catchError(e => {
      console.error("An error occurred computing changes in cache chunks", e);
      return EMPTY;
    }),
    // Include manual changes
    mergeWith(this.manualChanges$),
    // Save the changes to cache
    concatMap(x => from(this.applyChanges(x))),
    // Ignore errors
    catchError(e => {
      console.error("An error occurred applying changes to cache", e);
      return EMPTY;
    }))));
    this.scopedSub.add(queue$.subscribe());
  }
  dispose() {
    this.context.reset();
    this.scopedSub?.unsubscribe();
    this.manualChanges$.complete();
  }
  mapChanges(oldMap, newMap) {
    const changes = {
      added: [],
      updated: [],
      removed: []
    };
    for (let [id, oldValue] of oldMap) {
      const newValue = newMap.get(id);
      if (!newValue) {
        changes.removed.push({
          id
        });
        continue;
      }
      if (oldValue === newValue) continue;
      if (this.ignoreValueChange.has(id)) continue;
      changes.updated.push({
        id,
        data: newValue
      });
    }
    for (let [id, newValue] of newMap) {
      if (oldMap.has(id)) continue;
      if (this.ignoreValueChange.has(id)) continue;
      changes.added.push({
        id,
        data: newValue
      });
    }
    this.ignoreValueChange.clear();
    return changes;
  }
  async applyChanges(changes) {
    if (!(await this.context.isAvailable())) return;
    await this.context.useTransaction(async trx => {
      for (let {
        id,
        data
      } of changes?.added ?? []) {
        await trx.addValue(id, data, this.getTags?.(data));
      }
      for (let {
        id,
        data
      } of changes?.updated ?? []) {
        await trx.updateValue(id, data);
      }
      for (let {
        id,
        newAge
      } of changes?.ageChanged ?? []) {
        await trx.updateValueAge(id, newAge);
      }
      for (let {
        id
      } of changes?.removed ?? []) {
        await trx.deleteValue(id);
      }
      for (let {
        id
      } of changes?.removedTags ?? []) {
        await trx.deleteTag(id);
      }
      await trx.commit();
    }, false);
  }
  //<editor-fold desc="Data Load">
  /**
   * Load an item and mark is as loaded
   * Should only be used to populate a store
   * @param id
   * @param options
   */
  loadItem(id, options) {
    if (!options?.maxAge) {
      return from(this.readItem(id)).pipe(map(x => x?.data));
    }
    return from(this.readItem(id)).pipe(map(val => {
      if (val === void 0) return void 0;
      const age = Date.now() - (options?.absoluteAge ? val.createdAt : val.updatedAt).getTime();
      if (age > options.maxAge) return void 0;
      return val.data;
    }));
  }
  /**
   * Ignore the next time this value is updated in the store
   * This is to skip the change resulting from this cache load
   * @param id
   */
  markAsLoaded(id) {
    this.ignoreValueChange.add(id);
  }
  /**
   * Load all items and mark them as loaded
   * Should only be used to populate a store
   * @param options
   */
  loadAll(options) {
    let values$;
    if (options?.maxAge) {
      values$ = from(this.readAll()).pipe(filterOld(options));
    } else {
      values$ = from(this.readAll());
    }
    return values$.pipe(tap(list => list?.forEach(x => this.ignoreValueChange.add(x.id))), map(list => list?.map(x => x.data)));
  }
  /**
   * Load all items with the given tag and mark them as loaded
   * Should only be used to populate a store
   * @param tag
   * @param options
   */
  loadFromTag(tag, options) {
    let values$;
    if (options?.maxAge) {
      values$ = from(this.readWithTag(tag)).pipe(filterOld(options));
    } else {
      values$ = from(this.readWithTag(tag));
    }
    return values$.pipe(tap(list => list?.forEach(x => this.ignoreValueChange.add(x.id))), map(list => list?.map(x => x.data)));
  }
  /**
   * Read a value from the cache
   * @param id
   */
  async readItem(id) {
    if (!(await this.context.isAvailable())) return void 0;
    return await this.context.useTransaction(trx => trx.readValue(id), true);
  }
  /**
   * Read all values from the cache
   */
  async readAll() {
    if (!(await this.context.isAvailable())) return [];
    return this.context.useTransaction(trx => trx.readAllValues(), true);
  }
  /**
   * Read all values from the cache with the given tag
   */
  async readWithTag(tag) {
    if (!(await this.context.isAvailable())) return [];
    return this.context.useTransaction(trx => trx.readValuesWithTag(tag), true);
  }
  //</editor-fold>
  emitManualChanges(changes) {
    if (!this.manualChanges$.closed) return;
    this.manualChanges$.next(changes);
  }
  resetItemAge(id) {
    this.emitManualChanges({
      ageChanged: [{
        id,
        newAge: /* @__PURE__ */new Date()
      }]
    });
  }
  clearTag(tag) {
    this.emitManualChanges({
      removedTags: [{
        id: tag
      }]
    });
  }
}
function filterOld(options) {
  return map(list => {
    const oldest = list.reduce((state, x) => {
      const time = (options.absoluteAge ? x.createdAt : x.updatedAt).getTime();
      if (time < state) return time;
      return state;
    }, Date.now());
    if (!oldest) return list;
    const age = Date.now() - oldest;
    if (age > options.maxAge) return void 0;
    return list;
  });
}
export { CacheChunk };