import { isObject } from './type-predicates.js';
function objEmpty(obj) {
  if (obj == null) return true;
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}
function iterateObj(obj, callback) {
  let index = 0;
  for (const [key, value] of Object.entries(obj)) {
    callback(value, key, index);
    index++;
  }
}
function mapObj(obj, mapVal, mapKey) {
  const result = {};
  for (let [key, val] of Object.entries(obj)) {
    const newKey = mapKey ? mapKey(val, key) : key;
    if (newKey === void 0) continue;
    const newVal = mapVal(val, key);
    if (newVal === void 0) continue;
    result[newKey] = newVal;
  }
  return result;
}
function arrToObj(arr, mapKey, mapVal) {
  const result = {};
  let index = 0;
  for (let val of arr) {
    const newKey = mapKey(val, index);
    result[newKey] = mapVal ? mapVal(val, index) : val;
    index++;
  }
  return result;
}
function deepEquals(item1, item2) {
  if (typeof item1 !== typeof item2) return false;
  if (item1 == null) return item2 == null;
  if (item2 == null) return false;
  if (Array.isArray(item1)) {
    if (!Array.isArray(item2)) return false;
    if (item1.length !== item2.length) return false;
    for (let i = 0; i < item1.length; i++) {
      if (!deepEquals(item1[i], item2[i])) return false;
    }
    return true;
  }
  if (item1 instanceof Date && item2 instanceof Date) {
    return item1.getTime() === item2.getTime();
  }
  if (isObject(item1)) {
    if (!isObject(item2)) return false;
    if (Object.keys(item1).length !== Object.keys(item2).length) return false;
    for (const key in item1) {
      if (!item1.hasOwnProperty(key)) continue;
      if (!item2.hasOwnProperty(key)) return false;
      if (!deepEquals(item1[key], item2[key])) return false;
    }
    return true;
  }
  return item1 === item2;
}
function shallowEquals(item1, item2) {
  if (Array.isArray(item1)) {
    if (!Array.isArray(item2)) return false;
    if (item1.length !== item2.length) return false;
    for (let i = 0; i < item1.length; i++) {
      if (item1[i] !== item2[i]) return false;
    }
    return true;
  }
  if (Array.isArray(item2)) return false;
  if (Object.keys(item1).length !== Object.keys(item2).length) return false;
  for (let key in item1) {
    if (!item1.hasOwnProperty(key)) continue;
    if (!item2.hasOwnProperty(key)) return false;
    if (item1[key] !== item2[key]) return false;
  }
  return true;
}
function deepFreeze(data) {
  if (data == null) return data;
  if (data instanceof Function) return Object.freeze(data);
  if (isObject(data)) {
    for (let key in data) {
      if (!data.hasOwnProperty(key)) continue;
      const val = data[key];
      if (val == null) continue;
      if (val instanceof Function || isObject(val)) deepFreeze(val);
    }
  }
  if (Object.isFrozen(data)) return data;
  return Object.freeze(data);
}
function deepCopy(source) {
  if (source === void 0) return void 0;
  if (source === null) return null;
  if (source instanceof Date) {
    return new Date(source.getTime());
  }
  if (source instanceof Set) {
    const ret = /* @__PURE__ */new Set();
    for (let val of source) {
      ret.add(val);
    }
    return ret;
  }
  if (source instanceof Map) {
    const ret = /* @__PURE__ */new Map();
    for (let [key, val] of source) {
      ret.set(key, val);
    }
    return ret;
  }
  if (Array.isArray(source)) {
    return source.map(x => deepCopy(x));
  }
  if (isObject(source)) {
    const ret = {};
    for (let [key, val] of Object.entries(source)) {
      ret[key] = deepCopy(val);
    }
    return ret;
  }
  return source;
}
export { arrToObj, deepCopy, deepEquals, deepFreeze, iterateObj, mapObj, objEmpty, shallowEquals };