class ReadonlyLookup {
  constructor() {
    this.values = /* @__PURE__ */new Map();
  }
  get size() {
    if (this.noKey == null) return this.values.size;
    return this.values.size + 1;
  }
  get(key) {
    if (key == null) return this.noKey;
    return this.values.get(key);
  }
  has(key) {
    if (key == null) return this.noKey != null;
    return this.values.has(key);
  }
  forEach(callback) {
    for (const [key, value] of this) {
      callback(value, key);
    }
  }
  *[Symbol.iterator]() {
    if (this.noKey != null) yield [void 0, this.noKey];
    for (let [key, value] of this.values) {
      yield [key, value];
    }
  }
}
class Lookup extends ReadonlyLookup {
  set(key, value) {
    if (key == null) this.noKey = value;else this.values.set(key, value);
    return this;
  }
  clear() {
    this.values.clear();
    this.noKey = void 0;
  }
  delete(key) {
    if (key != null) return this.values.delete(key);
    if (this.noKey == null) return false;
    this.noKey = void 0;
    return true;
  }
  add(key, value) {
    if (key == null) {
      if (this.noKey == null) this.noKey = [value];else this.noKey.push(value);
      return this;
    }
    const list = this.values.get(key);
    if (list) list.push(value);else this.values.set(key, [value]);
    return this;
  }
}
export { Lookup, ReadonlyLookup };