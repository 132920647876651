import { StoreService } from './store-service.js';
import { dashCase } from '@juulsgaard/ts-tools';
import { CacheConfig } from './caching/cache-config.js';
import { ReplaySubject, skip } from 'rxjs';
import { CacheCommandConfig } from './configs/cache-command-config.js';
class CacheStoreService extends StoreService {
  constructor(initialState, configService, databaseContext) {
    super(initialState, configService);
    this.databaseContext = databaseContext;
    this.states$ = new ReplaySubject();
    this.storeId = dashCase(this.constructor.name);
    this.states$.next(this.state$);
  }
  reset() {
    if (this.disposed) throw Error("The store has been disposed");
    this.states$.next(this.state$.pipe(skip(1)));
    super.reset();
  }
  dispose() {
    super.dispose();
    this.states$.complete();
  }
  cache(chunkId, version) {
    return new CacheConfig(`${this.storeId}_${chunkId}`, version, this.databaseContext, this.states$);
  }
  cacheCommand(chunk) {
    return new CacheCommandConfig(this.context, chunk);
  }
}
export { CacheStoreService };