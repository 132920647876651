import { rootReducerScope, objectReducerScope, createActionReducerCoalesce, applyScopedObjectReducer, listReducerScope } from '../models/reducer-scope.js';
import { CacheCommand } from '../commands/cache-command.js';
import { tap } from 'rxjs';
import { parseIdMap } from '../lib/id-map.js';
class CacheCommandConfig {
  constructor(context, cache) {
    this.context = context;
    this.cache = cache;
  }
  /**
   * Create an action based on loading an entire cache chunk
   * Optionally provide a custom payload as a type param
   */
  fromAll() {
    return new CacheCommandObjectConfig(this.context, {
      initialLoad: false,
      action: ({
        options
      }) => this.cache.loadAll(options),
      failCondition: x => x.length === 0,
      cacheWhenOnline: true,
      fallbackWhenOffline: true,
      cancelConcurrent: false
    }, rootReducerScope, []);
  }
  fromTag(map) {
    const tagMap = map ? parseIdMap(map) : x => x;
    const cacheOptions = {
      initialLoad: false,
      action: ({
        options,
        payload
      }) => this.cache.loadFromTag(tagMap(payload), options),
      failCondition: x => x.length === 0,
      cacheWhenOnline: true,
      fallbackWhenOffline: true,
      cancelConcurrent: false
    };
    return new CacheCommandObjectConfig(this.context, cacheOptions, rootReducerScope, []);
  }
  fromSingle(map) {
    const idMap = map ? parseIdMap(map) : x => x;
    const cacheOptions = {
      initialLoad: false,
      action: ({
        options,
        payload
      }) => {
        let id = idMap(payload);
        return this.cache.loadItem(id, options).pipe(tap(x => {
          if (x === void 0) return;
          if (cacheOptions.failCondition?.(x)) return;
          this.cache.markAsLoaded(id);
        }));
      },
      cacheWhenOnline: true,
      fallbackWhenOffline: true,
      cancelConcurrent: false
    };
    return new CacheCommandObjectConfig(this.context, cacheOptions, rootReducerScope, []);
  }
}
class CacheCommandOptionConfig {
  constructor(options) {
    this.options = options;
  }
  /**
   * Define an error message for is the cache load fails
   * @param message - The message
   */
  withErrorMessage(message) {
    this.options.errorMessage = message;
    return this;
  }
  /**
   * Define a success message to show the user on cache load success
   * @param message - The message / message factory
   */
  withSuccessMessage(message) {
    this.options.successMessage = message;
    return this;
  }
  /**
   * Mark the cache action as being an initial load
   */
  isInitial(requestId) {
    this.options.initialLoad = true;
    this.options.requestId = requestId ?? this.options.requestId;
    return this;
  }
  /**
   * Cancels requests that happen while one of similar type / id is ongoing
   */
  cancelConcurrent(requestId) {
    this.options.cancelConcurrent = true;
    this.options.requestId = requestId ?? this.options.requestId;
    return this;
  }
  /**
   * Assign a request id to individual actions
   * @param requestId - Request Id generator
   */
  withRequestId(requestId) {
    this.options.requestId = requestId;
    return this;
  }
  /** Tell the command to not use the cache if the client is online */
  skipCacheOnline() {
    this.options.cacheWhenOnline = false;
    return this;
  }
  /** Tell the command to not use the fallback command if the client is online */
  skipFallbackOffline() {
    this.options.fallbackWhenOffline = false;
    return this;
  }
  maxAge(age, absolute = false) {
    this.options.onlineMaxAge = age;
    this.options.offlineMaxAge = age;
    this.options.onlineAbsoluteAge = absolute;
    this.options.offlineAbsoluteAge = absolute;
    return this;
  }
  maxAgeOffline(age, absolute = false) {
    this.options.offlineMaxAge = age;
    this.options.offlineAbsoluteAge = absolute;
    return this;
  }
  maxAgeOnline(age, absolute = false) {
    this.options.onlineMaxAge = age;
    this.options.onlineAbsoluteAge = absolute;
    return this;
  }
}
class CacheCommandObjectConfig extends CacheCommandOptionConfig {
  constructor(context, options, scope, path) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.path = path;
  }
  /**
   * Target a property on the object
   * @param key - The property name
   * @param coalesce - A default value to use if property isn't found
   */
  targetProp(key, coalesce) {
    const path = [...this.path, key.toString()];
    return new CacheCommandObjectConfig(this.context, this.options, objectReducerScope(this.scope, key, path, createActionReducerCoalesce(coalesce)), path);
  }
  /**
   * Target a list property on the object
   * @param key - The property name
   * @param create - Add list if it doesn't exist
   */
  targetList(key, create = false) {
    const path = [...this.path, key.toString()];
    return new CacheCommandListConfig(this.context, this.options, objectReducerScope(this.scope, key, path, create ? [] : void 0), path);
  }
  /**
   * Target a unit array property on the object
   * @param key - The property name
   * @param create - Add list if it doesn't exist
   */
  targetArray(key, create = false) {
    const path = [...this.path, key.toString()];
    return new CacheCommandArrayConfig(this.context, this.options, objectReducerScope(this.scope, key, path, create ? [] : void 0), path);
  }
  /**
   * Apply a modification to the payload / data before the reducer
   * @param func - The data modification
   */
  modify(func) {
    return new CacheCommandObjectDataConfig(this.context, this.options, this.scope, func);
  }
  withReducer(reducer) {
    return new CacheCommandEffectConfig(this.context, this.options, (root, data, payload) => this.scope(root, {
      data,
      payload
    }, state => applyScopedObjectReducer(state, reducer(data, state, payload))));
  }
}
class CacheCommandArrayConfig extends CacheCommandOptionConfig {
  constructor(context, options, scope, path) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.path = path;
  }
  /**
   * Apply a modification to the payload / data before the reducer
   * @param func - The data modification
   */
  modify(func) {
    return new CacheCommandListDataConfig(this.context, this.options, this.scope, func);
  }
  withReducer(reducer) {
    return new CacheCommandEffectConfig(this.context, this.options, (root, data, payload) => this.scope(root, {
      data,
      payload
    }, state => reducer(data, state, payload)));
  }
}
class CacheCommandListConfig extends CacheCommandArrayConfig {
  /**
   * Target a list item in the list
   * @param selector - The selector for the list item
   * @param coalesce - A default value to append if item isn't found
   */
  targetItem(selector, coalesce) {
    const path = [...this.path, "[]"];
    return new CacheCommandObjectConfig(this.context, this.options, listReducerScope(this.scope, ({
      data,
      payload
    }) => selector(data, payload), path, createActionReducerCoalesce(coalesce)), path);
  }
}
class CacheCommandObjectDataConfig extends CacheCommandOptionConfig {
  constructor(context, options, scope, modify) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.modify = modify;
  }
  /**
   * Define the reducer for the active scope
   * @param reducer
   */
  withReducer(reducer) {
    return new CacheCommandEffectConfig(this.context, this.options, (root, data, payload) => this.scope(root, {
      data,
      payload
    }, state => applyScopedObjectReducer(state, reducer(this.modify(data, payload, state), state))));
  }
}
class CacheCommandListDataConfig extends CacheCommandOptionConfig {
  constructor(context, options, scope, modify) {
    super(options);
    this.context = context;
    this.scope = scope;
    this.modify = modify;
  }
  /**
   * Define the reducer for the active scope
   * @param reducer
   */
  withReducer(reducer) {
    return new CacheCommandEffectConfig(this.context, this.options, (root, data, payload) => this.scope(root, {
      data,
      payload
    }, state => reducer(this.modify(data, payload, state), state)));
  }
}
class CacheCommandEffectConfig {
  constructor(context, options, reducer) {
    this.context = context;
    this.options = options;
    this.reducer = reducer;
  }
  withFallback(command) {
    return new CacheCommand(this.context, this.options, this.reducer, command, false);
  }
  withConcurrent(command) {
    return new CacheCommand(this.context, this.options, this.reducer, command, true);
  }
  noFallback() {
    return new CacheCommand(this.context, this.options, this.reducer);
  }
}
export { CacheCommandConfig };