import { ILoadingState } from './loading-state.interface.js';
import { of, throwError, firstValueFrom } from 'rxjs';
import { IValueLoadingState } from './value-loading-state.interface.js';
import { map } from 'rxjs/operators';
class ErrorLoadingState extends ILoadingState {
  constructor(error) {
    super();
    this.error = error;
    this.loading$ = of(false);
    this.failed$ = of(true);
    this.loading = false;
    this.isAsync = false;
    this.error$ = of(error);
  }
  cancel() {}
  /**
   * Promise implementation, always errors
   * @param next - Is never called
   * @param error - Is always called with the error
   */
  then(next, error) {
    error?.(this.error);
    return this;
  }
  /**
   * Promise implementation, always errors
   * @param func - Immediate gets called with error
   */
  catch(func) {
    func(this.error);
    return this;
  }
  /**
   * Promise implementation, is called immediately
   * @param func - Immediate gets called
   */
  finally(func) {
    func();
    return this;
  }
}
class ValueErrorLoadingState extends IValueLoadingState {
  constructor(error) {
    super();
    this.error = error;
    this.loading$ = of(false);
    this.failed$ = of(true);
    this.loading = false;
    this.isAsync = false;
    this.error$ = of(void 0).pipe(map(error));
    this.result$ = throwError(error);
  }
  get resultAsync() {
    return firstValueFrom(this.result$);
  }
  cancel() {}
}
export { ErrorLoadingState, ValueErrorLoadingState };