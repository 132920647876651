import { Lookup } from '../structures/lookup.js';
function arrToLookup(array, getKey, getVal) {
  const lookup = new Lookup();
  let index = 0;
  for (let item of array) {
    const key = getKey(item, index);
    const val = getVal ? getVal(item, index) : item;
    lookup.add(key, val);
    index++;
  }
  return lookup;
}
export { arrToLookup };