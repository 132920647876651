import { BehaviorSubject, Subject, Subscription, isObservable, distinctUntilChanged, Observable, filter, tap } from 'rxjs';
import { StoreClientCommandConfig, StoreCommandConfig } from './configs/command-config.js';
import { map } from 'rxjs/operators';
import { deepCopy, titleCase, arrToMap, deepFreeze } from '@juulsgaard/ts-tools';
import { BaseCommand } from './models/base-commands.js';
import { cache } from '@juulsgaard/rxjs-tools';
class StoreService {
  constructor(initialState, configService) {
    this.initialState = initialState;
    this.configService = configService;
    //<editor-fold desc="Loading State">
    /**
     * The current loading state of all commands
     * @private
     */
    this.loadStates = /* @__PURE__ */new Map();
    /**
     * The current loading state of all commands grouped on RequestId
     * @private
     */
    this.requestLoadStates = /* @__PURE__ */new Map();
    //</editor-fold>
    //<editor-fold desc="Failure State">
    /**
     * The current failure state of all commands
     * @private
     */
    this.errorStates = /* @__PURE__ */new Map();
    /**
     * The current failure state of all commands grouped on RequestId
     * @private
     */
    this.requestErrorStates = /* @__PURE__ */new Map();
    this._disposed$ = new BehaviorSubject(false);
    this.disposed$ = this._disposed$.asObservable();
    this._state$ = new BehaviorSubject(this.freeze(deepCopy(initialState)));
    this.state$ = this._state$.pipe(cache());
    const name = this.constructor.name.replace(/(^[_\W+]+|[_\W]+$)/g, "");
    this.storeName = titleCase(name);
    this.startQueue();
    this.context = {
      getCommandName: cmd => this.getCommandName(cmd),
      applyCommand: reducer$ => this.reducerQueue$.next(reducer$),
      getLoadState: (cmd, requestId) => this.getLoadState$(cmd, requestId).value,
      getLoadState$: (cmd, requestId) => this.getLoadState$(cmd, requestId).asObservable(),
      getErrorState$: (cmd, requestId) => this.getErrorState$(cmd, requestId).asObservable(),
      getFailureState$: (cmd, requestId) => this.getFailureState$(cmd, requestId),
      displayError: this.configService.displayError.bind(this.configService),
      displaySuccess: this.configService.displaySuccess.bind(this.configService),
      logActionRetry: this.configService.logActionRetry.bind(this.configService),
      startLoad: (cmd, requestId) => {
        this.startLoad(cmd, void 0);
        if (requestId) this.startLoad(cmd, requestId);
      },
      endLoad: (cmd, requestId) => {
        this.endLoad(cmd, void 0);
        if (requestId) this.endLoad(cmd, requestId);
      },
      failLoad: (cmd, error, requestId) => {
        this.failLoad(cmd, error, void 0);
        if (requestId) this.failLoad(cmd, error, requestId);
      },
      resetFailState: (cmd, requestId) => {
        this.resetFailState(cmd, void 0);
        if (requestId) this.resetFailState(cmd, requestId);
      },
      isProduction: this.configService.isProduction,
      errorIsCritical: this.configService.errorIsCritical.bind(this.configService)
    };
  }
  /**
   * Get the context object from a store.
   * This can be used to extend the Store with custom commands.
   * @param store - The store to extract the context from
   */
  static ExtractContext(store) {
    return store.context;
  }
  /**
   * The current state
   */
  get state() {
    return this._state$.value;
  }
  get actionNames() {
    if (this._actionNames) return this._actionNames;
    this._actionNames = arrToMap(Object.entries(this).filter(([_, val]) => val instanceof BaseCommand), ([_, val]) => val, ([key]) => titleCase(key));
    return this._actionNames;
  }
  //<editor-fold desc="Queue Logic">
  /**
   * Clear and set up the Reducer queue
   * @private
   */
  startQueue() {
    if (this.disposed) throw Error("The store has been disposed");
    this.queueSub?.unsubscribe();
    this.reducerQueue$ = new Subject();
    const subs = new Subscription();
    const queue = [];
    const typeQueues = /* @__PURE__ */new Set();
    let transaction;
    const self = this;
    function dequeue() {
      if (transaction) return;
      if (!queue.length) return;
      const actionIndex = queue.findIndex(x => !typeQueues.has(x.type));
      if (actionIndex < 0) return;
      const action = queue.splice(actionIndex, 1)[0];
      if (action.runInTransaction) runTransaction(action);else if (action.queued) runQueued(action);else run(action);
    }
    function applyReducer(reducer) {
      self.applyState(reducer(self._state$.value));
    }
    function run(action) {
      subs.add(action.run().subscribe(applyReducer));
      dequeue();
    }
    function runTransaction(action) {
      const snapshot = self._state$.value;
      transaction = action.run();
      function finish() {
        transaction = void 0;
        dequeue();
      }
      subs.add(transaction.subscribe({
        next: applyReducer,
        error: () => {
          applyReducer(() => snapshot);
          finish();
        },
        complete: finish
      }));
    }
    function runQueued(action) {
      typeQueues.add(action.type);
      function finish() {
        typeQueues.delete(action.type);
        dequeue();
      }
      subs.add(action.run().subscribe({
        next: applyReducer,
        error: finish,
        complete: finish
      }));
    }
    subs.add(this.reducerQueue$.subscribe(action => {
      queue.push(action);
      dequeue();
    }));
    this.queueSub = subs;
  }
  //</editor-fold>
  /**
   * Apply a new state to the store
   * @param state - The new state
   * @private
   */
  applyState(state) {
    if (this._state$.value === state) return false;
    this._state$.next(this.freeze(state));
    return true;
  }
  //<editor-fold desc="Get Load State">
  /**
   * Get a subject with the loading state of a Command
   * @param cmd - The command
   * @param requestId - An optional RequestId
   * @private
   */
  getLoadState$(cmd, requestId) {
    if (!requestId) {
      let sub2 = this.loadStates.get(cmd);
      if (sub2) return sub2;
      sub2 = new BehaviorSubject(void 0);
      this.loadStates.set(cmd, sub2);
      return sub2;
    }
    let map2 = this.requestLoadStates.get(cmd);
    if (!map2) {
      map2 = /* @__PURE__ */new Map();
      this.requestLoadStates.set(cmd, map2);
    }
    let sub = map2.get(requestId);
    if (sub) return sub;
    sub = new BehaviorSubject(void 0);
    map2.set(requestId, sub);
    return sub;
  }
  //</editor-fold>
  //<editor-fold desc="Get Fail State">
  /**
   * Get a subject with the failure state of a Command
   * @param cmd - The command
   * @param requestId - An optional RequestId
   * @private
   */
  getErrorState$(cmd, requestId) {
    if (!requestId) {
      let sub2 = this.errorStates.get(cmd);
      if (sub2) return sub2;
      sub2 = new BehaviorSubject(void 0);
      this.errorStates.set(cmd, sub2);
      return sub2;
    }
    let map2 = this.requestErrorStates.get(cmd);
    if (!map2) {
      map2 = /* @__PURE__ */new Map();
      this.requestErrorStates.set(cmd, map2);
    }
    let sub = map2.get(requestId);
    if (sub) return sub;
    sub = new BehaviorSubject(void 0);
    map2.set(requestId, sub);
    return sub;
  }
  getFailureState$(cmd, requestId) {
    return this.getErrorState$(cmd, requestId).pipe(map(x => x != null));
  }
  /**
   * Get a subject with the failure state of a Command
   * @param cmd - The command
   * @param requestId - An optional RequestId
   * @private
   */
  getFailureStateOrDefault$(cmd, requestId) {
    if (!requestId) {
      return this.errorStates.get(cmd);
    }
    return this.requestErrorStates.get(cmd)?.get(requestId);
  }
  //</editor-fold>
  //<editor-fold desc="Manage Fail / Load state">
  /**
   * Mark a command as having started loading
   * @param cmd - The command
   * @param requestId
   * @private
   */
  startLoad(cmd, requestId) {
    const sub = this.getLoadState$(cmd, requestId);
    sub.next((sub.value ?? 0) + 1);
    this.resetFailState(cmd, requestId);
  }
  /**
   * Mark a command as having finished loading
   * @param cmd - The command
   * @param requestId
   * @private
   */
  endLoad(cmd, requestId) {
    const sub = this.getLoadState$(cmd, requestId);
    sub.next((sub.value ?? 1) - 1);
    this.resetFailState(cmd, requestId);
  }
  /**
   * Mark a command as having finished loading with an error
   * @param cmd - The command
   * @param error
   * @param requestId
   * @private
   */
  failLoad(cmd, error, requestId) {
    this.getErrorState$(cmd, requestId).next(error);
    const sub = this.getLoadState$(cmd, requestId);
    const val = sub.value ?? 1;
    if (cmd.initialLoad && val === 1) {
      sub.next(void 0);
      return;
    }
    sub.next(val - 1);
  }
  resetFailState(cmd, requestId) {
    this.getFailureStateOrDefault$(cmd, requestId)?.next(void 0);
  }
  //</editor-fold>
  /**
   * Get the display name of a command
   * @param cmd
   * @private
   */
  getCommandName(cmd) {
    return `[${this.storeName}] ${this.actionNames.get(cmd) ?? "N/A"}`;
  }
  /**
   * Apply deep freeze on an object
   * Only freezes in dev environments
   * @param data - The data to freeze
   * @private
   */
  freeze(data) {
    if (!this.configService.isProduction) return deepFreeze(data);
    return data;
  }
  /**
   * Reset the entire store
   */
  reset() {
    if (this.disposed) throw Error("The store has been disposed");
    this.startQueue();
    this._state$.next(this.freeze(deepCopy(this.initialState)));
    this.loadStates.forEach(x => x.next(void 0));
    this.requestLoadStates.forEach(cmd => cmd.forEach(x => x.next(void 0)));
  }
  get disposed() {
    return this._disposed$.value;
  }
  /**
   * Dispose Store
   */
  dispose() {
    if (this.disposed) return;
    this._disposed$.next(true);
    this._disposed$.complete();
    this.queueSub?.unsubscribe();
    this._state$.complete();
  }
  command(client) {
    return client ? new StoreClientCommandConfig(this.context, client) : new StoreCommandConfig(this.context);
  }
  selector(pipe) {
    if (isObservable(pipe)) {
      return pipe.pipe(distinctUntilChanged(), cache());
    }
    return pipe(this.state$).pipe(distinctUntilChanged(), cache());
  }
  selectorFactory(builder, getId) {
    getId ?? (getId = x => x);
    const lookup = /* @__PURE__ */new Map();
    const getSelector = payload => {
      const id = getId(payload);
      let selector = lookup.get(id);
      if (!selector) {
        selector = builder(payload).pipe(distinctUntilChanged(),
        // Remove the observable when it's no longer used
        tap({
          finalize: () => lookup.delete(id)
        }),
        // Multicast the selector
        cache());
        lookup.set(id, selector);
      }
      return selector;
    };
    return payload => new Observable(subscriber => {
      return getSelector(payload).subscribe(subscriber);
    });
  }
  /**
   * Create a basic selector
   * @protected
   * @param selector - A method to map the state to the desired shape
   */
  select(selector) {
    return this.selector(state$ => state$.pipe(map(selector)));
  }
  selectNotNull(selector, modify) {
    return this.selector(state$ => {
      const base$ = state$.pipe(map(selector), filter(x => x != null));
      if (!modify) return base$;
      return base$.pipe(map(modify));
    });
  }
}
export { StoreService };