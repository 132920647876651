import { isObservable } from 'rxjs';
import { DelayedLoadingState } from './delayed-loading-state.js';
import { EmptyLoadingState } from './empty-loading-state.js';
import { LoadingState } from './loading-state.js';
import { StaticLoadingState } from './static-loading-state.js';
import { ValueErrorLoadingState, ErrorLoadingState } from './error-loading-state.js';
import { isFunction } from '@juulsgaard/ts-tools';
var Loading;
(Loading2 => {
  function Async(value$) {
    return new LoadingState(value$);
  }
  Loading2.Async = Async;
  function Any(value$) {
    if (isObservable(value$) || value$ instanceof Promise) {
      return new LoadingState(value$);
    }
    return new StaticLoadingState(value$);
  }
  Loading2.Any = Any;
  function Delayed(action, modify) {
    return new DelayedLoadingState(action, modify);
  }
  Loading2.Delayed = Delayed;
  function FromError(error) {
    if (isFunction(error)) return new ValueErrorLoadingState(error);
    return new ErrorLoadingState(error);
  }
  Loading2.FromError = FromError;
  function Empty() {
    return new EmptyLoadingState();
  }
  Loading2.Empty = Empty;
  function Static(value) {
    return new StaticLoadingState(value);
  }
  Loading2.Static = Static;
})(Loading || (Loading = {}));
export { Loading };