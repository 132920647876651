import { distinctUntilChanged, map } from 'rxjs/operators';
import { Future } from './future.js';
class FutureConfig {
  constructor(value$, loading$, error$, mapper) {
    this.value$ = value$;
    this.loading$ = loading$;
    this.error$ = error$;
    this.mapper = mapper;
  }
  withMapper(map2) {
    return new FutureConfig(this.value$, this.loading$, this.error$, x => x != void 0 ? map2(x) ?? void 0 : void 0);
  }
  withLoadCall(onLoad) {
    this.onLoad = onLoad;
    return this;
  }
  withDeleteCall(onDelete) {
    this.onDeleted = onDelete;
    return this;
  }
  generate() {
    const value$ = this.value$.pipe(distinctUntilChanged(), map(x => this.mapper(x)));
    return new Future(value$, this.loading$, this.error$, this.onLoad, this.onDeleted);
  }
}
export { FutureConfig };