function arrToMap(array, getKey, getVal) {
  const map = /* @__PURE__ */new Map();
  let index = 0;
  for (let item of array) {
    map.set(getKey(item, index), getVal ? getVal(item, index) : item);
    index++;
  }
  return map;
}
function objToMap(obj, mapKey, mapVal) {
  const map = /* @__PURE__ */new Map();
  for (let key in obj) {
    const val = obj[key];
    map.set(mapKey ? mapKey(val, key) : key, mapVal ? mapVal(val, key) : val);
  }
  return map;
}
function iterateMap(map, callback) {
  for (let [key, val] of map.entries()) {
    callback(val, key);
  }
}
export { arrToMap, iterateMap, objToMap };