function sortAlphAsc(getString) {
  if (!getString) return (a, b) => (a ?? "").localeCompare(b ?? "");
  return (a, b) => {
    const stringA = getString(a);
    const stringB = getString(b);
    return (stringA ?? "").localeCompare(stringB ?? "");
  };
}
function sortAlphDesc(getString) {
  if (!getString) return (a, b) => (b ?? "").localeCompare(a ?? "");
  return (a, b) => {
    const stringA = getString(a);
    const stringB = getString(b);
    return (stringB ?? "").localeCompare(stringA ?? "");
  };
}
export { sortAlphAsc, sortAlphDesc };