import { share, ReplaySubject, timer, Observable, switchMap } from 'rxjs';
function cache() {
  return share({
    connector: () => new ReplaySubject(1, Infinity),
    resetOnError: false,
    resetOnComplete: false,
    resetOnRefCountZero: true
  });
}
function persistentCache(duration = 1e3) {
  return share({
    connector: () => new ReplaySubject(1, Infinity),
    resetOnError: false,
    resetOnComplete: false,
    resetOnRefCountZero: () => timer(duration)
  });
}
function permanentCache(releaseOnValue = false) {
  return source => {
    let subject;
    let sub;
    let refCount = 0;
    const getSubject = () => {
      if (subject) return subject;
      subject = new ReplaySubject(1, Infinity);
      sub = source.subscribe({
        next: value => {
          if (!subject) return;
          if (releaseOnValue && refCount < 1) {
            subject = void 0;
            sub?.unsubscribe();
            sub = void 0;
            return;
          }
          subject.next(value);
        },
        error: err => subject?.error(err),
        complete: () => subject?.complete()
      });
      return subject;
    };
    return new Observable(subscriber => {
      refCount++;
      subscriber.add(() => refCount--);
      const subject2 = getSubject();
      return subject2.subscribe(subscriber);
    });
  };
}
function uniqueCache() {
  return source => {
    let subject = new ReplaySubject(1, Infinity);
    let sub;
    let refCount = 0;
    let hasValue = false;
    let lastValue;
    return new Observable(subscriber => {
      refCount++;
      if (!sub) {
        let first = true;
        sub = source.subscribe({
          next: value => {
            if (first) {
              first = false;
              if (hasValue) {
                if (lastValue === value) return;
              }
            }
            hasValue = true;
            lastValue = value;
            subject.next(value);
          },
          error: err => subject.error(err),
          complete: () => subject.complete()
        });
      }
      const subscription = subject.subscribe(subscriber);
      return () => {
        subscription.unsubscribe();
        refCount--;
        if (refCount === 0) {
          sub?.unsubscribe();
          sub = void 0;
        }
      };
    });
  };
}
function cachedSwitchMap(mapFunc) {
  return source => new Observable(subscriber => {
    let subscription;
    const sub = source.subscribe({
      next: x => {
        subscription?.unsubscribe();
        const subject = new ReplaySubject();
        subscription = mapFunc(x).subscribe(subject);
        subscriber.next(subject);
      },
      error: x => subscriber.error(x),
      complete: () => subscriber.complete()
    });
    return () => {
      sub.unsubscribe();
      subscription?.unsubscribe();
    };
  });
}
function voidableCache(mapFunc) {
  return source => source.pipe(cachedSwitchMap(x => mapFunc(x)), cache(), switchMap(x => x));
}
function voidablePersistentCache(mapFunc, duration = 1e3) {
  return source => source.pipe(cachedSwitchMap(x => mapFunc(x)), persistentCache(duration), switchMap(x => x));
}
function voidablePermanentCache(mapFunc, releaseOnValue = false) {
  return source => source.pipe(cachedSwitchMap(x => mapFunc(x)), permanentCache(), switchMap(x => x));
}
export { cache, permanentCache, persistentCache, uniqueCache, voidableCache, voidablePermanentCache, voidablePersistentCache };