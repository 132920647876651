class ActionCommandError extends Error {
  constructor(message, payload) {
    super(message);
    this.payload = payload;
  }
}
class CacheCommandError extends Error {
  constructor(message, payload) {
    super(message);
    this.payload = payload;
  }
}
class ActionCancelledError extends Error {
  constructor(message, payload) {
    super(message);
    this.payload = payload;
  }
}
class CancelledError extends Error {
  constructor() {
    super("Cancelled");
  }
}
export { ActionCancelledError, ActionCommandError, CacheCommandError, CancelledError };