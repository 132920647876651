import { isFunction } from '@juulsgaard/ts-tools';
function createActionReducerCoalesce(coalesce) {
  if (coalesce === void 0) return void 0;
  if (!isFunction(coalesce)) return coalesce;
  return ({
    data,
    payload
  }, state) => coalesce(data, payload, state);
}
function rootReducerScope(root, data, func) {
  return func(root);
}
function objectReducerScope(prevReducer, key, path, coalesce, modify) {
  return (root, data, func) => {
    return prevReducer(root, data, state => {
      let val = state[key];
      if (coalesce !== void 0 && val === void 0) {
        val = isFunction(coalesce) ? coalesce(data, state) : coalesce;
      }
      if (val === void 0) {
        console.warn(`Object prop '${path.join(".")}' not found in reducer`);
        return state;
      }
      if (modify) {
        state = modify(data, state);
      }
      const delta = func(val);
      if (delta === val) return state;
      return {
        ...state,
        [key]: delta
      };
    });
  };
}
function listReducerScope(prevReducer, selector, path, coalesce) {
  return (root, data, func) => {
    const filter = selector(data);
    return prevReducer(root, data, state => {
      let index = state.findIndex(x => filter(x));
      if (coalesce !== void 0 && index < 0) index = state.length;
      if (index < 0) {
        console.warn(`List element '${path.join(".")}' not found in reducer`);
        return state;
      }
      const val = index === state.length && coalesce !== void 0 ? isFunction(coalesce) ? coalesce(data, state) : coalesce : state[index];
      const delta = func(val);
      if (delta === val) return state;
      const newState = [...state];
      newState.splice(index, 1, delta);
      return newState;
    });
  };
}
function applyScopedObjectReducer(state, data) {
  if (data === state) return state;
  return {
    ...state,
    ...data
  };
}
export { applyScopedObjectReducer, createActionReducerCoalesce, listReducerScope, objectReducerScope, rootReducerScope };