import {Injectable} from "@angular/core";
import {CacheDatabaseContext, IndexedDbAdapter} from "@juulsgaard/store-service";

@Injectable({providedIn: 'root'})
export class IdentityDatabase extends CacheDatabaseContext {

  constructor(adapter: IndexedDbAdapter) {
    super(adapter, 'identity');
  }
}
